import { action, decorate, observable, runInAction } from 'mobx';
class HomeStore {
  homeFilter = {
    filter: 'HOT',
  };

  async fetchResultByBrandTimer(action = true, rootStore, allGames) {}

  changeHomeFilter(filter) {
    runInAction(() => {
      this.homeFilter = filter;
    });
  }

  dehydrate() {
    return {
      homeFilter: this.homeFilter,
    };
  }
}

decorate(HomeStore, {
  homeFilter: observable,
  fetchResultByBrandTimer: action,
});

export default HomeStore;
