import { action, decorate, observable, runInAction } from 'mobx';

import { resultsHist } from '@services/trends';

class TrendChartStore {
  singleGame;
  selectedBall;

  async fetchTrendChart(gameCode) {
    if (gameCode) {
      const singleGameRequest = await resultsHist(gameCode);

      let single30Game = [...singleGameRequest];
      single30Game = single30Game.slice(0, 30);

      if (single30Game && single30Game.length) {
        single30Game.reverse();
      }

      runInAction(() => {
        this.singleGame = single30Game;
      });
    }
  }

  setBall(ball) {
    runInAction(() => {
      this.selectedBall = ball;
    });
  }

  cleanUp() {
    runInAction(() => {
      this.singleGame = '';
      this.selectedBall = '';
    });
  }

  dehydrate() {
    return {
      singleGame: this.singleGame,
      selectedBall: this.selectedBall,
    };
  }
}

decorate(TrendChartStore, {
  singleGame: observable,
  selectedBall: observable,
  fetchTrendChart: action,
  setChartData: action,
  cleanUp: action,
});

export default TrendChartStore;
