import { action, decorate, observable, runInAction } from 'mobx';
import { serviceResultDragonByGame } from '@services/changLong';

class ChangLongStore {
  filterGameList = [];
  dragonList = [];
  limiter = 2;

  setFilterGameList(gameUniqueId, gameNameInChinese) {
    runInAction(() => {
      this.filterGameList.push({
        gameUniqueId: gameUniqueId,
        gameNameInChinese: gameNameInChinese,
      });

      this.fetchResultDragonByGame(gameUniqueId, gameNameInChinese);
    });
  }

  removeFilterGameList(game) {
    const index = this.filterGameList.findIndex(
      (list) => list.gameUniqueId === game,
    );
    runInAction(() => {
      if (index > -1) {
        this.filterGameList.splice(index, 1);

        const newDragonList = this.dragonList.filter(function (obj) {
          return obj.gameUniqueId !== game;
        });
        this.dragonList = newDragonList;
      }
    });
  }

  async fetchResultDragonByGame(gameUniqueId, gameNameInChinese) {
    const resultDragon = await serviceResultDragonByGame(gameUniqueId);

    const resultDragonObject = {
      gameUniqueId: gameUniqueId,
      dragon: resultDragon,
      gameNameInChinese: gameNameInChinese,
    };

    runInAction(() => {
      this.dragonList.push(resultDragonObject);
    });
  }

  setLimiter(limit) {
    runInAction(() => {
      this.limiter = limit;
    });
  }

  selectAll(allGames) {
    this.clearAll();
    for (let index = 0; index < allGames.length; index++) {
      if (allGames[index]) {
        this.setFilterGameList(
          allGames[index].gameUniqueId,
          allGames[index].gameNameInChinese,
        );
      }
    }
  }

  clearAll() {
    this.filterGameList.clear();
    this.dragonList.clear();
  }

  dehydrate() {
    return {
      filterGameList: this.filterGameList,
      dragonList: this.dragonList,
      limiter: this.limiter,
    };
  }
}

decorate(ChangLongStore, {
  filterGameList: observable,
  dragonList: observable,
  limiter: observable,
  setFilterGameList: action,
  removeFilterGameList: action,
  fetchResultDragonByGame: action,
  setLimiter: action,
  selectAll: action,
  clearAll: action,
});

export default ChangLongStore;
