import { observable, decorate, action } from 'mobx';

import { identitiesLocalService } from '@services/general';
class GeneralStore {
  clientId = '';
  brand = '';

  async fetchInitial() {
    const { clientId, brand } = await identitiesLocalService();
    this.clientId = clientId;
    this.brand = brand;
  }

  dehydrate() {
    return {
      clientId: this.clientId,
      brand: this.brand,
    };
  }
}

decorate(GeneralStore, {
  clientId: observable,
  brand: observable,
  fetchInitial: action,
});

export default GeneralStore;
