import { fetchAPI } from '@helpers/fetch';

export async function resultsHist(gameCode, brand) {
  return fetchAPI(
    `result/service/mobile/results/hist/${gameCode}?brand=${
      brand || 'guest'
    }&limit=101`,
  );
}

export async function currentTwo(gameCode, brand) {
  return fetchAPI(
    `result/service/mobile/results/currentTwo/${gameCode}?brand=${
      brand || 'guest'
    }`,
  );
}

export async function resultHistByDate(gameCode, date, brand) {
  console.log('api', brand);
  return fetchAPI(
    `result/service/mobile/results/histByDate?gameUniqueId=${gameCode}&brand=${
      brand || 'guest'
    }&date=${date}`,
  );
}

export async function getPrizeSettings() {
  return fetchAPI(`adminsettings/user/prizeSettings?clientId=1`);
}
