import { action, decorate, observable, runInAction } from 'mobx';

import {
  resultsHist,
  currentTwo,
  resultHistByDate,
  getPrizeSettings,
} from '@services/trends';

import { serviceResultDragonByGame } from '@services/changLong';

import { constChangLongGroup } from '@const/config';

let timerToStop;
let timerToNextDraw;
class TrendsStore {
  singleGame = [];
  allSingleGame = [];
  findSingleGame = [];
  currentTwoGame = 0;
  histByDate = [];
  histByDateBy19Days = [];
  mainOption;
  subOption = [];
  subSubOption = {
    ballNumber: [],
    oddEven: '',
    bigSmall: '',
    pairNumber: false,
  };
  luZhuBallNumber = [0];
  luZhuBallType = ['bigSmall'];
  sixBall = [];
  sixAttribute = '生肖';
  sixCriteria = [];
  dragonList = [];
  prizeSettings = 0;
  resultDisplay = 30;

  async fetchTrends(gameCode, brand) {
    if (gameCode) {
      const singleGameRequest = await resultsHist(gameCode, brand);

      let single30Game = [...singleGameRequest];
      single30Game = single30Game.slice(0, this.resultDisplay);

      runInAction(() => {
        this.singleGame = single30Game;
        this.allSingleGame = singleGameRequest;
      });
    }
  }

  async changeResultNumber(value) {
    if (value) {
      let newSingleGame = [...this.allSingleGame];
      newSingleGame = newSingleGame.slice(0, value);

      runInAction(() => {
        this.singleGame = newSingleGame;
        this.resultDisplay = value;
      });
    }
  }

  async fetchCurrentTwo(gameCode, group, brand) {
    if (gameCode) {
      const currentTwoRequest = await currentTwo(gameCode, brand);
      this.fetchHistByDate(gameCode, null, brand);
      if (constChangLongGroup.includes(group)) {
        this.fetchResultDragonByGame(gameCode, brand);
      }

      runInAction(() => {
        this.currentTwoGame = currentTwoRequest;

        if (typeof currentTwoRequest === 'object') {
          const timerToNextOpen = currentTwoRequest.lastOpen.openCode
            ? currentTwoRequest.lastOpen.nextStopOrderTimeEpoch -
              currentTwoRequest.current.currentTimeEpoch
            : 5;
          timerToStop = setTimeout(async () => {
            const timerCurrentTwoRequest = await currentTwo(gameCode, brand);
            if (constChangLongGroup.includes(group)) {
              this.fetchResultDragonByGame(gameCode, brand);
            }
            runInAction(() => {
              this.currentTwoGame = timerCurrentTwoRequest;
            });
            timerToNextDraw = setTimeout(() => {
              // console.log("timerToNextDraw");
              this.fetchCurrentTwo(gameCode, group, brand);
              this.fetchTrends(gameCode, brand);
              if (constChangLongGroup.includes(group)) {
                this.fetchResultDragonByGame(gameCode, brand);
              }
            }, 5000);
          }, (timerToNextOpen + 5) * 1000);
        }
      });
    }
  }

  clearTimeout() {
    clearTimeout(timerToStop);
    clearTimeout(timerToNextDraw);
  }

  async fetchHistByDate(gameCode, date, brand) {
    if (gameCode) {
      let fullDate;

      if (date) {
        fullDate = date;
      } else {
        const newDate = new Date();
        const strYear = newDate.getFullYear().toString();

        let strMonth = newDate.getMonth() + 1;
        strMonth =
          strMonth < 10 ? '0' + strMonth.toString() : strMonth.toString();
        const strDay = ('0' + newDate.getDate()).slice(-2).toString();

        fullDate = strYear + '-' + strMonth + '-' + strDay;
      }

      const histByDateRequest = await resultHistByDate(
        gameCode,
        fullDate,
        brand,
      );

      runInAction(() => {
        this.histByDate = histByDateRequest;
      });
    }
  }

  async fetchHistByDate19Days(gameCode, brand) {
    if (
      (gameCode && this.histByDateBy19Days.length === 0) ||
      this.histByDateBy19Days[0][0].gameUniqueId !== gameCode
    ) {
      let newDate = new Date();
      let histBy19Days = [];
      for (let index = 1; index < 20; index++) {
        let last = new Date(newDate.getTime() - index * 24 * 60 * 60 * 1000);

        const strYear = last.getFullYear().toString();

        let strMonth = last.getMonth() + 1;
        strMonth =
          strMonth < 10 ? '0' + strMonth.toString() : strMonth.toString();
        const strDay = ('0' + last.getDate()).slice(-2).toString();

        const fullDate = strYear + '-' + strMonth + '-' + strDay;

        const histByDateRequest = await resultHistByDate(
          gameCode,
          fullDate,
          brand,
        );

        if (
          Array.isArray(histByDateRequest) &&
          histByDateRequest.length !== 0
        ) {
          histBy19Days.push(histByDateRequest);
        }
      }

      runInAction(() => {
        this.histByDateBy19Days = histBy19Days;
      });
    }
  }

  setSubOptions(value, gameCode) {
    if (value.includes('numberAnalysis') && this.histByDate === 0) {
      this.fetchHistByDate(gameCode);
    }

    runInAction(() => {
      this.subOption = value;
    });
  }

  setSubSubOption(value, type) {
    const index = this.subSubOption.ballNumber.indexOf(value);
    runInAction(() => {
      if (index > -1) {
        this.subSubOption.ballNumber.splice(index, 1);
      } else {
        if (type === 1) {
          this.subSubOption.ballNumber.push(value);
          this.subSubOption.oddEven = '';
          this.subSubOption.bigSmall = '';
          this.subSubOption.pairNumber = false;
        } else if (type === 2 && this.subSubOption.oddEven !== value) {
          this.subSubOption.ballNumber = [];
          this.subSubOption.oddEven = value;
          this.subSubOption.pairNumber = false;
        } else if (type === 3 && this.subSubOption.bigSmall !== value) {
          this.subSubOption.ballNumber = [];
          this.subSubOption.bigSmall = value;
          this.subSubOption.pairNumber = false;
        } else if (type === 4) {
          this.subSubOption.ballNumber = [];
          this.subSubOption.oddEven = '';
          this.subSubOption.bigSmall = '';
          this.subSubOption.pairNumber = true;
        } else {
          if (type === 2) {
            this.subSubOption.oddEven = '';
            this.subSubOption.pairNumber = false;
          } else if (type === 3) {
            this.subSubOption.bigSmall = '';
            this.subSubOption.pairNumber = false;
          }
        }
      }
    });
  }

  setPairNumber(value) {
    runInAction(() => {
      this.subSubOption.pairNumber = value;
    });
  }

  resetSubSubOption() {
    runInAction(() => {
      this.subSubOption = {
        ballNumber: [],
        oddEven: '',
        bigSmall: '',
        pairNumber: '',
      };
    });
  }

  resetCurrentTwo() {
    runInAction(() => {
      this.currentTwoGame = 0;
      this.histByDate = 0;
    });
  }

  resetObservable() {
    runInAction(() => {
      this.singleGame = [];
      this.allSingleGame = [];
      this.currentTwoGame = 0;
      this.histByDate = [];
      this.mainOption = 0;
      this.subOption = [];
      this.sixBall = [];
      this.subSubOption = {
        ballNumber: [],
        oddEven: '',
        bigSmall: '',
        pairNumber: false,
      };
    });
  }

  setLuZhuBallNumber(value) {
    runInAction(() => {
      this.luZhuBallNumber = value;
    });
  }

  setLuZhuBallType(value) {
    runInAction(() => {
      this.luZhuBallType = value;
    });
  }

  setAllLuZhuBallNumber(number, type) {
    this.setLuZhuBallNumber(number);
    this.setLuZhuBallType(type);
  }

  resetLuzhuBall(number) {
    this.setLuZhuBallNumber([number]);
    this.setLuZhuBallType(['bigSmall']);
  }

  setSixBall(value) {
    runInAction(() => {
      this.sixBall = value;
    });
  }

  setSixAttribute(value) {
    runInAction(() => {
      this.sixAttribute = value;
      this.sixCriteria = [];
    });
  }

  setSixCriteria(value) {
    runInAction(() => {
      this.sixCriteria = value;
    });
  }

  async fetchResultDragonByGame(gameUniqueId, brand) {
    const resultDragon = await serviceResultDragonByGame(gameUniqueId, brand);

    runInAction(() => {
      this.dragonList = resultDragon;
    });
  }

  async fetchPrizeSettings() {
    const resultPrizeSettings = await getPrizeSettings();

    runInAction(() => {
      this.prizeSettings = resultPrizeSettings;
    });
  }

  dehydrate() {
    return {
      singleGame: this.singleGame,
      allSingleGame: this.allSingleGame,
      currentTwoGame: this.currentTwoGame,
      mainOption: this.mainOption,
      histByDate: this.histByDate,
      subSubOption: this.subSubOption,
      luZhuBallNumber: this.luZhuBallNumber,
      luZhuBallType: this.luZhuBallType,
      sixBall: this.sixBall,
      sixAttribute: this.sixAttribute,
      sixCriteria: this.sixCriteria,
      histByDateBy19Days: this.histByDateBy19Days,
      prizeSettings: this.prizeSettings,
    };
  }
}

decorate(TrendsStore, {
  singleGame: observable,
  allSingleGame: observable,
  findSingleGame: observable,
  currentTwoGame: observable,
  histByDate: observable,
  mainOption: observable,
  subOption: observable,
  subSubOption: observable,
  luZhuBallNumber: observable,
  luZhuBallType: observable,
  sixBall: observable,
  sixAttribute: observable,
  sixCriteria: observable,
  dragonList: observable,
  histByDateBy19Days: observable,
  prizeSettings: observable,
  resultDisplay: observable,
  fetchTrends: action,
  fetchCurrentTwo: action,
  fetchHistByDate: action,
  fetchHistByDate19Days: action,
  setSubOptions: action,
  setSubSubOption: action,
  resetSubSubOption: action,
  setPairNumber: action,
  clearTimeout: action,
  resetObservable: action,
  resetLuzhuBall: action,
  setAllLuZhuBallNumber: action,
  fetchResultDragonByGame: action,
  fetchPrizeSettings: action,
  resetCurrentTwo: action,
  changeResultNumber: action,
});

export default TrendsStore;
