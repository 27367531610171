import { color } from '@helpers/const';

export const constPlayRule = {
  jiShiKaiJiang: 'jiShiKaiJiang',
  changLongTiXing: 'changLongTiXing',
  kaiJiangJilu: 'kaiJiangJilu',
  luZhuFenXi: 'luZhuFenXi',
  zongHeFenXi: 'zongHeFenXi',
  lengReFenXi: 'lengReFenXi',
  jiBenZouShi: 'jiBenZouShi',
  dingWeiZouShi: 'dingWeiZouShi',
  longHuZouShi: 'longHuZouShi',
  xingTaiZouShi: 'xingTaiZouShi',
  haoMaLuzhu: 'haoMaLuzhu',
  daXiaoDanShuangLuzhu: 'daXiaoDanShuangLuzhu',
  zongHeLuZhu: 'zongHeLuZhu',
  guanYaHeLuZhu: 'guanYaHeLuZhu',
  haoMaTongJi: 'haoMaTongJi',
  liangMianTongJi: 'liangMianTongJi',
  changLongTongJi: 'changLongTongJi',
  liShiHaoMa: 'liShiHaoMa',
  daXiaoDanShuangLiShi: 'daXiaoDanShuangLiShi',
  pkZhouShi: 'pkZhouShi',
};

export const constPlayRuleChineseName = {
  jiShiKaiJiang: '即时开奖',
  kaiJiangJilu: '开奖记录',
  changLongTiXing: '长龙提醒',
  luZhuFenXi: '路珠分析',
  zongHeFenXi: '综合分析',
  lengReFenXi: '冷热分析',
  jiBenZouShi: '基本走势',
  dingWeiZouShi: '定位走势',
  longHuZouShi: '龙虎走势',
  xingTaiZouShi: '形态走势',
  haoMaLuzhu: '号码路珠',
  daXiaoDanShuangLuzhu: '大小单双路珠',
  zongHeLuZhu: '总和路珠',
  guanYaHeLuZhu: '冠亚和路珠',
  haoMaTongJi: '号码统计',
  liangMianTongJi: '两面统计',
  changLongTongJi: '每日长龙统计',
  liShiHaoMa: '历史号码统计',
  daXiaoDanShuangLiShi: '大小单双历史',
  pkZhouShi: '扑克走势',
};

export const constTrendBallList = [
  { text: '第一球', ballIndex: '0' },
  { text: '第二球', ballIndex: '1' },
  { text: '第三球', ballIndex: '2' },
  { text: '第四球', ballIndex: '3' },
  { text: '第五球', ballIndex: '4' },
  { text: '第六球', ballIndex: '5' },
  { text: '第七球', ballIndex: '6' },
  { text: '第八球', ballIndex: '7' },
  { text: '第九球', ballIndex: '8' },
  { text: '第十球', ballIndex: '9' },
];

export const constLuZhuOptionBall = {
  SSC: [
    { label: '第一球', value: 0 },
    { label: '第二球', value: 1 },
    { label: '第三球', value: 2 },
    { label: '第四球', value: 3 },
    { label: '第五球', value: 4 },
    { label: '总和', value: 5, total: true },
  ],
  PK10: [
    { label: '冠军', value: 0 },
    { label: '亚军', value: 1 },
    { label: '第三名', value: 2 },
    { label: '第四名', value: 3 },
    { label: '第五名', value: 4 },
    { label: '第六名', value: 5 },
    { label: '第七名', value: 6 },
    { label: '第八名', value: 7 },
    { label: '第九名', value: 8 },
    { label: '第十名', value: 9 },
    { label: '冠亚和', value: 10, total: true },
  ],
  XY10: [
    { label: '冠军', value: 0 },
    { label: '亚军', value: 1 },
    { label: '第三名', value: 2 },
    { label: '第四名', value: 3 },
    { label: '第五名', value: 4 },
    { label: '第六名', value: 5 },
    { label: '第七名', value: 6 },
    { label: '第八名', value: 7 },
    { label: '第九名', value: 8 },
    { label: '第十名', value: 9 },
    { label: '冠亚和', value: 10, total: true },
  ],
  D11: [
    { label: '第一球', value: 0 },
    { label: '第二球', value: 1 },
    { label: '第三球', value: 2 },
    { label: '第四球', value: 3 },
    { label: '第五球', value: 4 },
    { label: '总和', value: 5, total: true },
  ],
  K3: [{ label: '总和', value: 0, total: true }],
  KL10F: [
    { label: '第一球', value: 0 },
    { label: '第二球', value: 1 },
    { label: '第三球', value: 2 },
    { label: '第四球', value: 3 },
    { label: '第五球', value: 4 },
    { label: '第六球', value: 5 },
    { label: '第七球', value: 6 },
    { label: '第八球', value: 7 },
    { label: '总和', value: 8, total: true },
  ],
};

export const constLuZhuName = {
  bigSmall: {
    text: '大小路珠',
    value: 'bigSmall',
  },
  oddEven: {
    text: '单双路珠',
    value: 'oddEven',
  },
  dragonTiger: {
    text: '龙虎',
    value: 'dragonTiger',
  },
  haoMa: {
    text: '号码',
    value: 'haoMa',
  },
};

export const constLuZhuOptionType = {
  SSC: [
    {
      label: constLuZhuName.bigSmall.text,
      value: constLuZhuName.bigSmall.value,
    },
    {
      label: constLuZhuName.oddEven.text,
      value: constLuZhuName.oddEven.value,
    },
    {
      label: constLuZhuName.dragonTiger.text,
      value: constLuZhuName.dragonTiger.value,
    },
  ],
  PK10: [
    {
      label: constLuZhuName.bigSmall.text,
      value: constLuZhuName.bigSmall.value,
    },
    {
      label: constLuZhuName.oddEven.text,
      value: constLuZhuName.oddEven.value,
    },
    {
      label: constLuZhuName.dragonTiger.text,
      value: constLuZhuName.dragonTiger.value,
    },
  ],
  XY10: [
    {
      label: constLuZhuName.bigSmall.text,
      value: constLuZhuName.bigSmall.value,
    },
    {
      label: constLuZhuName.oddEven.text,
      value: constLuZhuName.oddEven.value,
    },
    {
      label: constLuZhuName.dragonTiger.text,
      value: constLuZhuName.dragonTiger.value,
    },
  ],
  D11: [
    {
      label: constLuZhuName.bigSmall.text,
      value: constLuZhuName.bigSmall.value,
    },
    {
      label: constLuZhuName.oddEven.text,
      value: constLuZhuName.oddEven.value,
    },
    {
      label: constLuZhuName.dragonTiger.text,
      value: constLuZhuName.dragonTiger.value,
    },
  ],
  K3: [
    {
      label: constLuZhuName.bigSmall.text,
      value: constLuZhuName.bigSmall.value,
    },
    {
      label: constLuZhuName.oddEven.text,
      value: constLuZhuName.oddEven.value,
    },
  ],
  KL10F: [
    {
      label: constLuZhuName.bigSmall.text,
      value: constLuZhuName.bigSmall.value,
    },
    {
      label: constLuZhuName.oddEven.text,
      value: constLuZhuName.oddEven.value,
    },
  ],
};

export const constSixBall = [
  '正码一',
  '正码二',
  '正码三',
  '正码四',
  '正码五',
  '正码六',
  '特码',
];

export const shunZhi = {
  firstThree: 'firstThree',
  middleThree: 'middleThree',
  lastThree: 'lastThree',
};

export const arraySixAttribute = ['生肖', '五行'];

export const constSixAttribute = {
  zodiac: '生肖',
  element: '五行',
};

export const arrayZodiac = [
  '鼠',
  '牛',
  '虎',
  '兔',
  '龙',
  '蛇',
  '马',
  '羊',
  '猴',
  '鸡',
  '狗',
  '猪',
  '猫',
];

export const objectZodiac = {
  鼠: '鼠',
  牛: '牛',
  虎: '虎',
  兔: '兔',
  龙: '龙',
  蛇: '蛇',
  马: '马',
  羊: '羊',
  猴: '猴',
  鸡: '鸡',
  狗: '狗',
  猪: '猪',
  猫: '猫',
};

export const objectElement = {
  金: '金',
  木: '木',
  水: '水',
  火: '火',
  土: '土',
};

export const arrayElement = ['金', '木', '水', '火', '土'];

export const element = {
  METAL: '金',
  WOOD: '木',
  WATER: '水',
  FIRE: '火',
  EARTH: '土',
};

const { EARTH, FIRE, METAL, WATER, WOOD } = element;

export const constSixElements = {
  2020: {
    startTime: 125,
    elements: {
      [EARTH]: [1, 14, 15, 22, 23, 30, 31, 44, 45],
      [FIRE]: [4, 5, 12, 13, 26, 27, 34, 35, 42, 43],
      [METAL]: [6, 7, 20, 21, 28, 29, 36, 37],
      [WATER]: [8, 9, 16, 17, 24, 25, 38, 39, 46, 47],
      [WOOD]: [2, 3, 10, 11, 18, 19, 32, 33, 40, 41, 48, 49],
    },
  },
  2021: {
    startTime: 212,
    elements: {
      [EARTH]: [1, 2, 15, 16, 23, 24, 31, 32, 45, 46],
      [FIRE]: [5, 6, 13, 14, 27, 28, 35, 36, 43, 44],
      [METAL]: [7, 8, 21, 22, 29, 30, 37, 38],
      [WATER]: [9, 10, 17, 18, 25, 26, 39, 40, 47, 48],
      [WOOD]: [3, 4, 11, 12, 19, 20, 33, 34, 41, 42, 49],
    },
  },
  2022: {
    startTime: 201,
    elements: {
      [EARTH]: [1, 2, 3, 16, 17, 24, 25, 32, 33, 46, 47],
      [FIRE]: [6, 7, 14, 15, 28, 29, 36, 37, 44, 45],
      [METAL]: [8, 9, 22, 23, 30, 31, 38, 39],
      [WATER]: [10, 11, 18, 19, 26, 27, 40, 41, 48, 49],
      [WOOD]: [4, 5, 12, 13, 20, 21, 34, 35, 42, 43],
    },
  },
  2023: {
    startTime: 122,
    elements: {
      [EARTH]: [2, 3, 4, 17, 18, 25, 26, 33, 34, 47, 48],
      [FIRE]: [7, 8, 15, 16, 29, 30, 37, 38, 45, 46],
      [METAL]: [1, 9, 10, 23, 24, 31, 32, 39, 40],
      [WATER]: [11, 12, 19, 20, 27, 28, 41, 42, 49],
      [WOOD]: [5, 6, 13, 14, 21, 22, 35, 36, 43, 44],
    },
  },
  2024: {
    startTime: 210,
    elements: {
      [EARTH]: [3, 4, 5, 18, 19, 26, 27, 34, 35, 48, 49],
      [FIRE]: [8, 9, 16, 17, 30, 31, 38, 39, 46, 47],
      [METAL]: [1, 2, 10, 11, 24, 25, 32, 33, 40, 41],
      [WATER]: [12, 13, 20, 21, 28, 29, 42, 43],
      [WOOD]: [6, 7, 14, 15, 22, 23, 36, 37, 44, 45],
    },
  },
  2025: {
    startTime: 129,
    elements: {
      [EARTH]: [4, 5, 6, 19, 20, 27, 28, 35, 36, 49],
      [FIRE]: [1, 9, 10, 17, 18, 31, 32, 39, 40, 47, 48],
      [METAL]: [2, 3, 11, 12, 25, 26, 33, 34, 41, 42],
      [WATER]: [13, 14, 21, 22, 29, 30, 43, 44],
      [WOOD]: [7, 8, 15, 16, 23, 24, 37, 38, 45, 46],
    },
  },
  2026: {
    startTime: 217,
    elements: {
      [EARTH]: [5, 6, 7, 20, 21, 28, 29, 36, 37],
      [FIRE]: [1, 2, 10, 11, 18, 19, 32, 33, 40, 41, 48, 49],
      [METAL]: [3, 4, 12, 13, 26, 27, 34, 35, 42, 43],
      [WATER]: [14, 15, 22, 23, 30, 31, 44, 45],
      [WOOD]: [8, 9, 16, 17, 24, 25, 38, 39, 46, 47],
    },
  },
  2027: {
    startTime: 206,
    elements: {
      [EARTH]: [6, 7, 8, 21, 22, 29, 30, 37, 38],
      [FIRE]: [2, 3, 11, 12, 19, 20, 33, 34, 41, 42, 49],
      [METAL]: [4, 5, 13, 14, 27, 28, 35, 36, 43, 44],
      [WATER]: [1, 15, 16, 23, 24, 31, 32, 45, 46],
      [WOOD]: [9, 10, 17, 18, 25, 26, 39, 40, 47, 48],
    },
  },
};

export const ZODIAC = {
  SHU: '鼠',
  NIU: '牛',
  HU: '虎',
  TU: '兔',
  LONG: '龙',
  SHE: '蛇',
  MA: '马',
  YANG: '羊',
  HOU: '猴',
  JI: '鸡',
  GOU: '狗',
  ZHU: '猪',
};

const { SHU, NIU, HU, TU, LONG, SHE, MA, YANG, HOU, JI, GOU, ZHU } = ZODIAC;

export const constZodiacNumber = {
  2020: {
    startTime: 125,
    zodiac: {
      [SHU]: [1, 13, 25, 37, 49],
      [NIU]: [12, 24, 36, 48],
      [HU]: [11, 23, 35, 47],
      [TU]: [10, 22, 34, 46],
      [LONG]: [9, 21, 33, 45],
      [SHE]: [8, 20, 32, 44],
      [MA]: [7, 19, 31, 43],
      [YANG]: [6, 18, 30, 42],
      [HOU]: [5, 17, 29, 41],
      [JI]: [4, 16, 28, 40],
      [GOU]: [3, 15, 27, 39],
      [ZHU]: [2, 14, 26, 38],
    },
  },
  2021: {
    startTime: 212,
    zodiac: {
      [SHU]: [2, 14, 26, 38],
      [NIU]: [1, 13, 25, 37, 49],
      [HU]: [12, 24, 36, 48],
      [TU]: [11, 23, 35, 47],
      [LONG]: [10, 22, 34, 46],
      [SHE]: [9, 21, 33, 45],
      [MA]: [8, 20, 32, 44],
      [YANG]: [7, 19, 31, 43],
      [HOU]: [6, 18, 30, 42],
      [JI]: [5, 17, 29, 41],
      [GOU]: [4, 16, 28, 40],
      [ZHU]: [3, 15, 27, 39],
    },
  },
  2022: {
    startTime: 201,
    zodiac: {
      [SHU]: [3, 15, 27, 39],
      [NIU]: [2, 14, 26, 38],
      [HU]: [1, 13, 25, 37, 49],
      [TU]: [12, 24, 36, 48],
      [LONG]: [11, 23, 35, 47],
      [SHE]: [10, 22, 34, 46],
      [MA]: [9, 21, 33, 45],
      [YANG]: [8, 20, 32, 44],
      [HOU]: [7, 19, 31, 43],
      [JI]: [6, 18, 30, 42],
      [GOU]: [5, 17, 29, 41],
      [ZHU]: [4, 16, 28, 40],
    },
  },
};

export const constCurrentYearZodiac = {
  2020: {
    startTime: 125,
    zodiac: SHU,
  },
  2021: {
    startTime: 212,
    zodiac: NIU,
  },
  2022: {
    startTime: 201,
    zodiac: HU,
  },
};

export const arraySixGameColor = ['red', 'blue', 'green'];

export const constSixGameColor = {
  red: '红',
  blue: '蓝',
  green: '绿',
};

export const constSixGameNumberColor = {
  red: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46],
  blue: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48],
  green: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49],
};

export const constFilterInChinese = {
  big: '大',
  small: '小',
  draw: '和',
  odd: '单',
  even: '双',
  dragon: '龙',
  tiger: '虎',
  coming: '总来',
  'not-coming': '没来',
  front: '前',
  back: '后',
};

export const constFilterRankSubOptions = {
  bigSmall: {
    shortText: '大小',
    option: [
      {
        label: '大',
        name: 'big',
      },
      {
        label: '小',
        name: 'small',
      },
      {
        label: '和',
        name: 'draw',
      },
    ],
  },
  oddEven: {
    shortText: '单双',
    option: [
      {
        label: '双',
        name: 'even',
      },
      {
        label: '单',
        name: 'odd',
      },
    ],
  },
  dragonTiger: {
    shortText: '龙虎',
    option: [
      {
        label: '龙',
        name: 'dragon',
      },
      {
        label: '虎',
        name: 'tiger',
      },
      {
        label: '和',
        name: 'draw',
      },
    ],
  },
  haoMa: {
    shortText: '',
    option: [
      {
        label: '总来',
        name: 'coming',
      },
      {
        label: '没来',
        name: 'not-coming',
      },
      {
        label: '前',
        name: 'front',
      },
      {
        label: '后',
        name: 'back',
      },
    ],
  },
};

export const basicColumnBackgroundColor = [
  color.lightYellow,
  color.lightRed,
  color.lightBlue,
  color.lightGreen,
  color.lightPurple,
  color.lightYellow,
  color.lightRed,
  color.lightBlue,
  color.lightGreen,
  color.lightPurple,
];

export const defaultXingTaiByGroup = {
  SSC: [
    {
      title: '前三形态',
      ball: [0, 1, 2],
    },
    {
      title: '中三形态',
      ball: [1, 2, 3],
    },
    {
      title: '后三形态',
      ball: [2, 3, 4],
    },
  ],
  D11: [
    {
      title: '前三形态',
      ball: [0, 1, 2],
    },
    {
      title: '中三形态',
      ball: [1, 2, 3],
    },
    {
      title: '后三形态',
      ball: [2, 3, 4],
    },
  ],
};

export const defaultDragonTigerByGroup = {
  SSC: [
    {
      label: '第一球vs第二球',
      value: [0, 1],
    },
    {
      label: '第一球vs第三球',
      value: [0, 2],
    },
    {
      label: '第一球vs第四球',
      value: [0, 3],
    },
    {
      label: '第一球vs第五球',
      value: [0, 4],
    },
    {
      label: '第二球vs第三球',
      value: [1, 2],
    },
    {
      label: '第二球vs第四球',
      value: [1, 3],
    },
    {
      label: '第二球vs第五球',
      value: [1, 4],
    },
    {
      label: '第三球vs第四球',
      value: [2, 3],
    },
    {
      label: '第三球vs第五球',
      value: [2, 4],
    },
    {
      label: '第四球vs第五球',
      value: [3, 4],
    },
  ],
  D11: [
    {
      label: '第一球vs第五球',
      value: [0, 4],
    },
  ],
  PK10: [
    {
      label: '第一球vs第十球',
      value: [0, 9],
    },
    {
      label: '第二球vs第九球',
      value: [1, 8],
    },
    {
      label: '第三球vs第八球',
      value: [2, 7],
    },
    {
      label: '第四球vs第七球',
      value: [3, 6],
    },
    {
      label: '第五球vs第六球',
      value: [4, 5],
    },
  ],
  XY10: [
    {
      label: '第一球vs第十球',
      value: [0, 9],
    },
    {
      label: '第二球vs第九球',
      value: [1, 8],
    },
    {
      label: '第三球vs第八球',
      value: [2, 7],
    },
    {
      label: '第四球vs第七球',
      value: [3, 6],
    },
    {
      label: '第五球vs第六球',
      value: [4, 5],
    },
  ],
};

export const constBigSmallThreshold = {
  SSC: 4,
  PK10: 5,
  XY10: 5,
  D11: 6,
  K3: 3,
  KL10F: 10,
  PCDD: 4,
  THREED: 4,
  QXC: 4,
  MAX3D: 4,
};

export const constTotalNumberBigSmallThreshold = {
  SSC: 22,
  PK10: {
    threshold: 11,
  },
  XY10: {
    threshold: 11,
  },
  D11: {
    threshold: 29,
    draw: 30,
  },
  K3: 10,
  KL10F: {
    threshold: 83,
    draw: 84,
  },
  PCDD: 13,
  QXC: 17,
  SIX: 174,
};

export const constLuZhuMobileNumber = {
  SSC: [
    {
      label: '号码0-2',
      value: [0, 1, 2],
    },
    {
      label: '号码3-5',
      value: [3, 4, 5],
    },
    {
      label: '号码6-8',
      value: [6, 7, 8],
    },
    {
      label: '号码9',
      value: [9],
    },
  ],
  PK10: [
    {
      label: '号码1-3',
      value: [1, 2, 3],
    },
    {
      label: '号码4-6',
      value: [4, 5, 6],
    },
    {
      label: '号码7-9',
      value: [7, 8, 9],
    },
    {
      label: '号码10',
      value: [10],
    },
  ],
  XY10: [
    {
      label: '号码1-3',
      value: [1, 2, 3],
    },
    {
      label: '号码4-6',
      value: [4, 5, 6],
    },
    {
      label: '号码7-9',
      value: [7, 8, 9],
    },
    {
      label: '号码10',
      value: [10],
    },
  ],
  D11: [
    {
      label: '号码1-3',
      value: [1, 2, 3],
    },
    {
      label: '号码4-6',
      value: [4, 5, 6],
    },
    {
      label: '号码7-9',
      value: [7, 8, 9],
    },
    {
      label: '号码10-11',
      value: [10, 11],
    },
  ],
  K3: [
    {
      label: '号码1-3',
      value: [1, 2, 3],
    },
    {
      label: '号码4-6',
      value: [4, 5, 6],
    },
  ],
  KL10F: [
    {
      label: '号码1-5',
      value: [1, 2, 3, 4, 5],
    },
    {
      label: '号码6-10',
      value: [6, 7, 8, 9, 10],
    },
    {
      label: '号码11-15',
      value: [11, 12, 13, 14, 15],
    },
    {
      label: '号码16-20',
      value: [16, 17, 18, 19, 20],
    },
  ],
};

export const constLuZhuMobileBall = {
  SSC: [
    {
      label: '第一球 - 第二球',
      value: [0, 1],
    },
    {
      label: '第三球 - 第四球',
      value: [2, 3],
    },
    {
      label: '第五球',
      value: [4],
    },
  ],
  PK10: [
    {
      label: '冠军 - 亚军',
      value: [0, 1],
    },
    {
      label: '第三球 - 第四球',
      value: [2, 3],
    },
    {
      label: '第五球 - 第六球',
      value: [4, 5],
    },
    {
      label: '第七球 - 第八球',
      value: [6, 7],
    },
    {
      label: '第九球 - 第十球',
      value: [8, 9],
    },
  ],
  XY10: [
    {
      label: '冠军 - 亚军',
      value: [0, 1],
    },
    {
      label: '第三球 - 第四球',
      value: [2, 3],
    },
    {
      label: '第五球 - 第六球',
      value: [4, 5],
    },
    {
      label: '第七球 - 第八球',
      value: [6, 7],
    },
    {
      label: '第九球 - 第十球',
      value: [8, 9],
    },
  ],
  D11: [
    {
      label: '第一球 - 第二球',
      value: [0, 1],
    },
    {
      label: '第三球 - 第四球',
      value: [2, 3],
    },
    {
      label: '第五球',
      value: [4],
    },
  ],
  K3: [
    {
      label: '第一球',
      value: [0],
    },
    {
      label: '第二球',
      value: [1],
    },
    {
      label: '第三球',
      value: [2],
    },
  ],
  KL10F: [
    {
      label: '第一球 - 第二球',
      value: [0, 1],
    },
    {
      label: '第三球 - 第四球',
      value: [2, 3],
    },
    {
      label: '第五球 - 第六球',
      value: [4, 5],
    },
    {
      label: '第七球 - 第八球',
      value: [6, 7],
    },
  ],
};

export const constLiShiHaoMaFilter = {
  SSC: [
    {
      label: '0 - 4号',
      value: [0, 1, 2, 3, 4],
    },
    {
      label: '5 - 9号',
      value: [5, 6, 7, 8, 9],
    },
    {
      label: '单双大小',
      value: ['odd', 'even', 'big', 'small'],
    },
    // {
    //     label: "龙虎和",
    //     value: ["dragon", "tiger", "equal"],
    // },
  ],
  D11: [
    {
      label: '1 - 5号',
      value: [1, 2, 3, 4, 5],
    },
    {
      label: '6 - 11号',
      value: [6, 7, 8, 9, 10, 11],
    },
    {
      label: '单双大小',
      value: ['odd', 'even', 'big', 'small'],
    },
    {
      label: '龙虎',
      value: ['dragon', 'tiger'],
    },
  ],
  K3: [
    {
      label: '1 - 3号',
      value: [1, 2, 3],
    },
    {
      label: '4 - 6号',
      value: [4, 5, 6],
    },
    {
      label: '大小',
      value: ['big', 'small'],
    },
  ],
  KL10F: [
    {
      label: '1 - 5号',
      value: [1, 2, 3, 4, 5],
    },
    {
      label: '6 - 10号',
      value: [6, 7, 8, 9, 10],
    },
    {
      label: '11 - 15号',
      value: [11, 12, 13, 14, 15],
    },
    {
      label: '16 - 20号',
      value: [16, 17, 18, 19, 20],
    },
    {
      label: '单双大小',
      value: ['odd', 'even', 'big', 'small'],
    },
  ],
};

export const constBallNameByGroup = {
  SSC: [
    { label: '第一球', value: 0 },
    { label: '第二球', value: 1 },
    { label: '第三球', value: 2 },
    { label: '第四球', value: 3 },
    { label: '第五球', value: 4 },
  ],
  PK10: [
    { label: '冠军', value: 0 },
    { label: '亚军', value: 1 },
    { label: '第三名', value: 2 },
    { label: '第四名', value: 3 },
    { label: '第五名', value: 4 },
    { label: '第六名', value: 5 },
    { label: '第七名', value: 6 },
    { label: '第八名', value: 7 },
    { label: '第九名', value: 8 },
    { label: '第十名', value: 9 },
  ],
  XY10: [
    { label: '冠军', value: 0 },
    { label: '亚军', value: 1 },
    { label: '第三名', value: 2 },
    { label: '第四名', value: 3 },
    { label: '第五名', value: 4 },
    { label: '第六名', value: 5 },
    { label: '第七名', value: 6 },
    { label: '第八名', value: 7 },
    { label: '第九名', value: 8 },
    { label: '第十名', value: 9 },
  ],
  D11: [
    { label: '第一球', value: 0 },
    { label: '第二球', value: 1 },
    { label: '第三球', value: 2 },
    { label: '第四球', value: 3 },
    { label: '第五球', value: 4 },
  ],
  K3: [
    { label: '第一球', value: 0 },
    { label: '第二球', value: 1 },
    { label: '第三球', value: 2 },
  ],
  KL10F: [
    { label: '小区', value: 0 },
    { label: '大区', value: 1 },
  ],
  XPK: [
    { label: '号码1-7', value: 0 },
    { label: '号码8-K', value: 1 },
    { label: '形态', value: 2 },
  ],
};

export const constLowFrequencyGame = {
  BJ28: ['jiShiKaiJiang'],
  FLCKL8: [
    'jiShiKaiJiang',
    'zongHeFenXi',
    'lengReFenXi',
    'jiBenZouShi',
    'longHuZouShi',
    'xingTaiZouShi',
  ],
  PL3: ['jiShiKaiJiang'],
  PL5: [
    'jiShiKaiJiang',
    'zongHeFenXi',
    'lengReFenXi',
    'jiBenZouShi',
    'longHuZouShi',
    'xingTaiZouShi',
  ],
  HF_TJSSC: [
    'jiShiKaiJiang',
    'zongHeFenXi',
    'lengReFenXi',
    'jiBenZouShi',
    'longHuZouShi',
    'xingTaiZouShi',
  ],
  HF_AHD11: [
    'jiShiKaiJiang',
    'lengReFenXi',
    'jiBenZouShi',
    'longHuZouShi',
    'xingTaiZouShi',
  ],
  QXC: ['jiShiKaiJiang'],
  X3D: ['jiShiKaiJiang'],
  HF_SHSSL: ['jiShiKaiJiang'],
  HF_GXK3: [
    'jiShiKaiJiang',
    'luZhuFenXi',
    'lengReFenXi',
    'jiBenZouShi',
    'haoMaLuzhu',
    'zongHeLuZhu',
    'haoMaTongJi',
    'changLongTongJi',
    'liShiHaoMa',
  ],
  HF_SHK3: [
    'jiShiKaiJiang',
    'luZhuFenXi',
    'lengReFenXi',
    'jiBenZouShi',
    'haoMaLuzhu',
    'zongHeLuZhu',
    'haoMaTongJi',
    'changLongTongJi',
    'liShiHaoMa',
  ],
  HF_CQKL10F: ['jiShiKaiJiang'],
  VNM3D: ['jiShiKaiJiang'],
};

export const constSuggestPlan = [
  {
    text: '计划一',
    plan: '0',
    betNumber: {
      SSC: 5,
      PK10: 5,
      D11: 5,
      SIX: 5,
      K3: 7,
      KL10F: 9,
      PCDD: 10,
      '3D': 5,
    },
  },
  {
    text: '计划二',
    plan: '1',
    betNumber: {
      SSC: 4,
      PK10: 4,
      D11: 4,
      SIX: 4,
      K3: 6,
      KL10F: 8,
      PCDD: 9,
      '3D': 4,
    },
  },
  {
    text: '计划三',
    plan: '2',
    betNumber: {
      SSC: 3,
      PK10: 3,
      D11: 3,
      SIX: 3,
      K3: 5,
      KL10F: 7,
      PCDD: 8,
      '3D': 3,
    },
  },
];

export const constHotWarmCold = {
  30: {
    hot: 6,
    warm: [3, 5],
    cold: [0, 2],
  },
  50: {
    hot: 10,
    warm: [4, 9],
    cold: [0, 3],
  },
  100: {
    hot: 20,
    warm: [6, 19],
    cold: [0, 5],
  },
};
