import { fetchAPI } from '@helpers/fetch';
// import { identitiesLocalService } from "./general";

export async function currentOrderByBrand() {
  // const { brand } = await identitiesLocalService();

  return fetchAPI(
    // `result/service/mobile/results/current/order/byBrand/${brand}`
    `result/service/mobile/results/current/order/byBrand/guest`,
  );
}

export async function getMarkSixByYearConversion() {
  return fetchAPI(`adminsettings/user/markSixByYear/conversion`);
}
