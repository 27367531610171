import React from 'react';
import { Provider } from 'mobx-react';
import rootStore from '@stores/rootStore';
import homeStore from '@stores/homeStore';
import generalStore from '@stores/generalStore';
import trendChartStore from '@stores/trendChartStore';
import changLongStore from '@stores/changLongStore';
import trendsStore from '@stores/trendsStore';
import { configure } from 'mobx';

configure({ enforceActions: 'observed' });

const root = new rootStore();

const allStores = {
  rootStore: root,
  generalStore: new generalStore(),
  homeStore: new homeStore(),
  trendChartStore: new trendChartStore(),
  changLongStore: new changLongStore(),
  trendsStore: new trendsStore(root),
};

export default ({ element }) => <Provider {...allStores}>{element}</Provider>;
