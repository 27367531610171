export const constPlayGroup = {
  SSC: 'SSC',
  SIX: 'SIX',
  PK10: 'PK10',
  D11: 'D11',
  XY10: 'XY10',
  K3: 'K3',
  KL10F: 'KL10F',
  THREED: 'THREED',
  XPK: 'XPK',
  QXC: 'QXC',
  PCDD: 'PCDD',
  OTHER: 'OTHER',
  MAX3D: 'MAX3D',
};

export const constPlayGroupInChinese = {
  SSC: '时时彩',
  PK10: 'PK拾',
  D11: '11选5',
  SIX: '六合彩',
  XY10: '幸运10',
  K3: '快3',
  KL10F: '快乐十分',
  OTHER: '其他',
  PCDD: 'PC蛋蛋',
  THREED: '3D',
  XPK: '扑克',
  QXC: '七星彩',
  MAX3D: 'MAX3D',
};

export const constGameUniqueId = {
  HF_FFSSC: 'HF_FFSSC',
  HF_LFSSC: 'HF_LFSSC',
  HF_CQSSC: 'HF_CQSSC',
  HF_TWSSC: 'HF_TWSSC',
  HF_XJSSC: 'HF_XJSSC',
  HF_HNWFC: 'HF_HNWFC',
  HF_TXFFC: 'HF_TXFFC',
  HF_HGSSC: 'HF_HGSSC',
  HF_AZSSC: 'HF_AZSSC',
  HF_HXFFC: 'HF_HXFFC',
  PL5: 'PL5',
  HF_AZXY5: 'HF_AZXY5',
  HF_FFPK10: 'HF_FFPK10',
  HF_LFPK10: 'HF_LFPK10',
  HF_XYFT: 'HF_XYFT',
  HF_XYSM: 'HF_XYSM',
  HF_WFSSC: 'HF_WFSSC',
  HF_WFPK10: 'HF_WFPK10',
  HF_BJPK10: 'HF_BJPK10',
  HF_AZXY10: 'HF_AZXY10',
  HF_FFFT: 'HF_FFFT',
  HF_LFFT: 'HF_LFFT',
  HF_WFD11: 'HF_WFD11',
  HF_FFD11: 'HF_FFD11',
  HF_LFD11: 'HF_LFD11',
  HF_SDD11: 'HF_SDD11',
  HF_SHD11: 'HF_SHD11',
  HF_JXD11: 'HF_JXD11',
  HF_GDD11: 'HF_GDD11',
  HF_FFMS: 'HF_FFMS',
  HF_LFMS: 'HF_LFMS',
  MARK_SIX: 'MARK_SIX',
  MC_MARK_SIX: 'MC_MARK_SIX',
  SG_MARK_SIX: 'SG_MARK_SIX',
  TW_MARK_SIX: 'TW_MARK_SIX',
  HF_JSMCMS: 'HF_JSMCMS',
  TW_TT_MARK_SIX: 'TW_TT_MARK_SIX',
  TT_MARK_SIX: 'TT_MARK_SIX',
  NMC_MARK_SIX: 'NMC_MARK_SIX',
  HF_JSMS: 'HF_JSMS',
  HF_FFK3: 'HF_FFK3',
  HF_LFK3: 'HF_LFK3',
  HF_WFK3: 'HF_WFK3',
  HF_HGK3: 'HF_HGK3',
  HF_AZK3: 'HF_AZK3',
  HF_AZXY8: 'HF_AZXY8',
  X3D: 'X3D',
  PL3: 'PL3',
  HF_JS3D: 'HF_JS3D',
  HF_SHSSL: 'HF_SHSSL',
  HF_XYPK: 'HF_XYPK',
  QXC: 'QXC',
  BJ28: 'BJ28',
  HF_LF28: 'HF_LF28',
  VNM3D: 'VNM3D',
};

export const constGameNameInChinese = {
  HF_FFSSC: '分分时时彩',
  HF_LFSSC: '二分时时彩',
  HF_WFSSC: '五分时时彩',
  HF_CQSSC: '重庆时时彩',
  HF_TWSSC: '台湾时时彩',
  HF_XJSSC: '新疆时时彩',
  HF_HGSSC: '韩国时时彩',
  HF_HNWFC: '河内五分',
  HF_TXFFC: '腾讯分分彩',
  HF_AZSSC: '澳洲时时彩',
  HF_HXFFC: '哈希时时彩',
  PL5: '排列五',
  HF_AZXY5: '澳洲幸运5',
  HF_FFPK10: '分分PK拾',
  HF_LFPK10: '二分PK拾',
  HF_WFPK10: '五分PK拾',
  HF_BJPK10: '北京PK拾',
  HF_AZXY10: '澳洲幸运10',
  HF_XYSM: '幸运赛马',
  HF_FFFT: '分分飞艇',
  HF_LFFT: '二分飞艇',
  HF_XYFT: '幸运飞艇',
  HF_FFD11: '分分11选5',
  HF_LFD11: '二分11选5',
  HF_WFD11: '五分11选5',
  HF_SDD11: '山东11选5',
  HF_SHD11: '上海11选5',
  HF_JXD11: '江西11选5',
  HF_GDD11: '广东11选5',
  HF_FFMS: '分分六合彩',
  HF_LFMS: '二分六合彩',
  MARK_SIX: '香港六合彩',
  MC_MARK_SIX: '澳门六合彩',
  SG_MARK_SIX: '新加坡六合彩',
  HF_JSMS: '极速六合彩',
  TW_MARK_SIX: '台湾大乐透',
  HF_JSMCMS: '极速澳门六合彩',
  TW_TT_MARK_SIX: '台湾六合彩',
  TT_MARK_SIX: '天天六合彩',
  NMC_MARK_SIX: '新澳门六合彩',
  HF_FFK3: '分分快3',
  HF_LFK3: '二分快3',
  HF_WFK3: '五分快3',
  HF_AZK3: '澳洲快3',
  HF_HGK3: '韩国快3',
  HF_AZXY8: '澳洲幸运8',
  X3D: '福彩3D',
  PL3: '排列三',
  HF_JS3D: '极速3D',
  HF_SHSSL: '上海时时乐',
  HF_XYPK: '新幸运扑克',
  QXC: '七星彩',
  BJ28: '北京28',
  HF_LF28: '幸运28',
  VNM3D: '越南Max3D',
};

export const constGameUniqueIdGroup = {
  HF_FFSSC: 'SSC',
  HF_LFSSC: 'SSC',
  HF_CQSSC: 'SSC',
  HF_TWSSC: 'SSC',
  HF_HNWFC: 'SSC',
  HF_TXFFC: 'SSC',
  HF_XJSSC: 'SSC',
  HF_HGSSC: 'SSC',
  HF_AZSSC: 'SSC',
  HF_HXFFC: 'SSC',
  PL5: 'SSC',
  HF_AZXY5: 'SSC',
  HF_FFPK10: 'PK10',
  HF_LFPK10: 'PK10',
  HF_XYFT: 'PK10',
  HF_XYSM: 'PK10',
  HF_WFSSC: 'PK10',
  HF_BJPK10: 'PK10',
  HF_WFPK10: 'PK10',
  HF_AZXY10: 'XY10',
  HF_FFFT: 'XY10',
  HF_LFFT: 'XY10',
  HF_WFD11: 'D11',
  HF_FFD11: 'D11',
  HF_LFD11: 'D11',
  HF_SDD11: 'D11',
  HF_SHD11: 'D11',
  HF_JXD11: 'D11',
  HF_GDD11: 'D11',
  HF_FFMS: 'SIX',
  HF_LFMS: 'SIX',
  MARK_SIX: 'SIX',
  MC_MARK_SIX: 'SIX',
  SG_MARK_SIX: 'SIX',
  TW_MARK_SIX: 'SIX',
  HF_JSMCMS: 'SIX',
  TW_TT_MARK_SIX: 'SIX',
  TT_MARK_SIX: 'SIX',
  NMC_MARK_SIX: 'SIX',
  HF_JSMS: 'SIX',
  HF_FFK3: 'K3',
  HF_LFK3: 'K3',
  HF_WFK3: 'K3',
  HF_HGK3: 'K3',
  HF_AZK3: 'K3',
  HF_AZXY8: 'KL10F',
  X3D: 'THREED',
  PL3: 'THREED',
  HF_JS3D: 'THREED',
  HF_SHSSL: 'THREED',
  HF_XYPK: 'XPK',
  QXC: 'QXC',
  BJ28: 'PCDD',
  HF_LF28: 'PCDD',
  VNM3D: 'THREED',
};

export const listOfSSCGames = [
  constGameUniqueId.HF_FFSSC,
  constGameUniqueId.HF_LFSSC,
  constGameUniqueId.HF_WFSSC,
  constGameUniqueId.HF_AZSSC,
  constGameUniqueId.HF_HXFFC,
  constGameUniqueId.HF_CQSSC,
  constGameUniqueId.HF_TWSSC,
  constGameUniqueId.HF_TXFFC,
  constGameUniqueId.HF_HNWFC,
  constGameUniqueId.HF_XJSSC,
  constGameUniqueId.HF_HGSSC,
  constGameUniqueId.PL5,
  constGameUniqueId.HF_AZXY5,
];

export const listOfPK10Games = [
  constGameUniqueId.HF_FFPK10,
  constGameUniqueId.HF_LFPK10,
  constGameUniqueId.HF_WFPK10,
  constGameUniqueId.HF_BJPK10,
  constGameUniqueId.HF_AZXY10,
];

export const listOfXY10Games = [
  constGameUniqueId.HF_XYSM,
  constGameUniqueId.HF_FFFT,
  constGameUniqueId.HF_LFFT,
  constGameUniqueId.HF_XYFT,
];

export const listOfD11Games = [
  constGameUniqueId.HF_FFD11,
  constGameUniqueId.HF_LFD11,
  constGameUniqueId.HF_WFD11,
  constGameUniqueId.HF_SDD11,
  constGameUniqueId.HF_SHD11,
  constGameUniqueId.HF_JXD11,
  constGameUniqueId.HF_GDD11,
];

export const listOfSIXGames = [
  constGameUniqueId.HF_FFMS,
  constGameUniqueId.HF_LFMS,
  constGameUniqueId.MARK_SIX,
  constGameUniqueId.MC_MARK_SIX,
  constGameUniqueId.SG_MARK_SIX,
  constGameUniqueId.TW_MARK_SIX,
  constGameUniqueId.HF_JSMCMS,
  constGameUniqueId.TW_TT_MARK_SIX,
  constGameUniqueId.TT_MARK_SIX,
  constGameUniqueId.NMC_MARK_SIX,
  constGameUniqueId.HF_JSMS,
];

export const listOfK3Games = [
  constGameUniqueId.HF_FFK3,
  constGameUniqueId.HF_LFK3,
  constGameUniqueId.HF_WFK3,
  constGameUniqueId.HF_AZK3,
  constGameUniqueId.HF_HGK3,
];

export const listOfKL10FGames = [constGameUniqueId.HF_AZXY8];

export const listOfTHREEDGames = [
  constGameUniqueId.X3D,
  constGameUniqueId.PL3,
  constGameUniqueId.HF_JS3D,
  constGameUniqueId.HF_SHSSL,
];

export const listOfXPKGames = [constGameUniqueId.HF_XYPK];

export const listOfQXCGames = [constGameUniqueId.QXC];

export const listOfPCDDGames = [
  constGameUniqueId.BJ28,
  constGameUniqueId.HF_LF28,
];

export const listOfMAX3DGames = [constGameUniqueId.VNM3D];

export const constTotalCodeDigit = {
  SSC: 5,
  PK10: 10,
  XY10: 10,
  D11: 5,
  K3: 3,
  KL10F: 8,
  PCDD: 3,
  THREED: 3,
  XPK: 3,
  QXC: 4,
  SIX: 7,
  MAX3D: 6,
};

export const constGameBallNumber = {
  SSC: 9,
  PK10: 10,
  XY10: 10,
  D11: 11,
  K3: 6,
  KL10F: 20,
  PCDD: 9,
  QXC: 9,
  THREED: 9,
  '3D': 9,
  SIX: 11, // this was used for zodiac
  MAX3D: 9,
};

export const constGameBallStartNumber = {
  SSC: 0,
  PK10: 1,
  XY10: 1,
  D11: 1,
  K3: 1,
  KL10F: 1,
  PCDD: 0,
  QXC: 0,
  THREED: 0,
  '3D': 0,
  SIX: 0, // this was used for zodiac
  MAX3D: 0,
};

export const totalOpenCodePerDay = {
  HF_FFSSC: 1320,
  HF_LFSSC: 660,
  HF_WFSSC: 265,
  HF_AZSSC: 288,
  HF_HXFFC: 1440,
  HF_TWSSC: 203,
  HF_BJSSC: 179,
  HF_HGSSC: 138,
  HF_CQSSC: 59,
  HF_TXFFC: 1440,
  HF_HNWFC: 288,
  HF_XJSSC: 48,
  HF_AZXY5: 288,
  HF_FFPK10: 1320,
  HF_LFPK10: 660,
  HF_WFPK10: 265,
  HF_AZXY10: 288,
  HF_BJPK10: 44,
  HF_XYSM: 264,
  HF_FFFT: 1320,
  HF_LFFT: 660,
  HF_XYFT: 180,
  HF_FFD11: 1320,
  HF_SDD11: 43,
  HF_LFD11: 660,
  HF_WFD11: 265,
  HF_SHD11: 45,
  HF_JXD11: 42,
  HF_GDD11: 42,
  HF_FFK3: 1320,
  HF_LFK3: 660,
  HF_WFK3: 265,
  HF_HGK3: 138,
  HF_AZK3: 288,
  HF_AZXY8: 288,
  HF_LF28: 660,
  X3D: 1,
  HF_JS3D: 264,
  HF_XYPK: 660,
  HF_JSMS: 264,
  VNM3D: 1,
};

export const constSuggestPlanBetRange = {
  SSC: {
    from: 0,
    to: 9,
  },
  PK10: {
    from: 1,
    to: 10,
  },
  XY10: {
    from: 1,
    to: 10,
  },
  D11: {
    from: 1,
    to: 11,
  },
  K3: {
    from: 3,
    to: 18,
  },
  KL10F: {
    from: 1,
    to: 18,
  },
  PCDD: {
    from: 0,
    to: 27,
  },
  QXC: {
    from: 0,
    to: 9,
  },
  THREED: {
    from: 0,
    to: 9,
  },
  SIX: {
    from: 0,
    to: 11,
  },
  '3D': {
    from: 0,
    to: 9,
  },
  MAX3D: {
    from: 0,
    to: 9,
  },
};
