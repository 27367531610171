import { webStorageGetOptionData } from '@helpers/fetch/webStorage';
import { fetchAPI } from '@helpers/fetch';

function identitiesService() {
  // return fetchAPI("account/webapi/account/users/adminid");
  return { adminId: '2', brand: 'guest' };
}

export async function identitiesLocalService() {
  return await webStorageGetOptionData('identitiesService', identitiesService);
}

const addZeroToDigit = (digi) => {
  return ('0' + digi).slice(-2);
};

export async function findUniqueIssueNumberCount(gameUniqueId) {
  const today = new Date();
  const date =
    today.getFullYear() +
    '-' +
    addZeroToDigit(today.getMonth() + 1) +
    '-' +
    addZeroToDigit(today.getDate());
  const currentTime =
    today.getHours() +
    ':' +
    addZeroToDigit(today.getMinutes()) +
    ':' +
    addZeroToDigit(today.getSeconds());

  const startDate = 'startDate=' + date + '-00:00:00';
  const endDate = 'stopDate=' + date + '-' + currentTime;

  return fetchAPI(
    `result/service/mobile/results/findUniqueIssueNumberCount/${gameUniqueId}?${startDate}&${endDate}`,
  );
}
