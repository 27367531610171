module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LCW","short_name":"LCW","start_url":"/app/home","background_color":"#FF7043","theme_color":"#FF7043","display":"minimal-ui","icon":"/data/jenkins_home/jobs/pc-web-lcw/workspace/src/components/images/logos/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8dde295700ff1a600b8b828ead10d4b4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
