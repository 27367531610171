/* @flow */

import axios from 'axios';
import { message } from 'antd';

let API_URL =
  typeof window !== 'undefined' && `${window.location.origin}/api/v1/`;
// if (
//     typeof window !== "undefined" &&
//     window.location.origin === "https://sit04-lcw-web.power4a.com"
// ) {
//     API_URL =
//         typeof window !== "undefined" &&
//         `https://sit04-201web.power4a.com/api/v1/`;
// }

export const fetchAPI = async (
  url: string,
  opts: any,
  baseURL: string = API_URL,
) => {
  axios.defaults.baseURL = baseURL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  try {
    // const { data } = await axios({
    //     url,
    //     ...opts,
    // });
    const { status, data, statusText } = await axios({
      url,
      ...opts,
    });

    if (status >= 200 && status < 300) {
      return data;
    } else {
      message.error(statusText);
    }
  } catch (error) {
    if (error.response) {
      return error.response.statusText;
    }
    if (error.request) {
      return error.request.statusText;
    }

    return error;
  }
};
