import { Images } from '@components/common/images';
import {
  constGameUniqueId,
  constPlayGroup,
  constPlayGroupInChinese,
  constGameNameInChinese,
  listOfSSCGames,
  listOfPK10Games,
  listOfXY10Games,
  listOfD11Games,
  listOfK3Games,
  listOfKL10FGames,
  listOfSIXGames,
  listOfTHREEDGames,
  listOfXPKGames,
  listOfQXCGames,
  listOfPCDDGames,
} from '@const/game';
import { constPlayRule, constPlayRuleChineseName } from '@const/playRule';

const PREFIXED_URI = '/app';

export const URLS = {
  home: `${PREFIXED_URI}/home`,
  lottery: `${PREFIXED_URI}/lottery`,
  aboutUs: `${PREFIXED_URI}/about-us`,
  trendChart: `${PREFIXED_URI}/trend-chart`,
  changLong: `${PREFIXED_URI}/chang-long`,
  trends: `${PREFIXED_URI}/trends`,
  iframe: `${PREFIXED_URI}/iframe`,
  suggestPlan: `${PREFIXED_URI}/suggest-plan`,
};

export const pageName = {
  zouShiTuBiao: 'ZouShiTuBiao',
  changLongTiXing: 'ChangLongTiXing',
  tuiJianJiHua: 'TuiJianJiHua',
};

export const pageNameChinese = {
  zouShiTuBiao: '走势图表',
  changLongTiXing: '长龙提醒',
  tuiJianJiHua: '推荐计划',
};

export const constHomeFilter = [
  {
    filter: 'HOT',
    nameInChinese: '热门彩',
    games: [
      constGameUniqueId.HF_FFSSC,
      constGameUniqueId.HF_LFSSC,
      constGameUniqueId.HF_FFPK10,
      constGameUniqueId.HF_LFPK10,
      constGameUniqueId.HF_FFK3,
      constGameUniqueId.HF_LFK3,
    ],
  },
  {
    filter: 'SSC',
    nameInChinese: '时时彩',
    showAll: true,
    // games:[
    //     constGameUniqueId.HF_FFSSC,
    //     constGameUniqueId.HF_LFSSC,
    //     constGameUniqueId.HF_WFSSC,
    //     constGameUniqueId.HF_TWSSC,
    //     constGameUniqueId.HF_TXFFC,
    //     constGameUniqueId.PL5,
    //     constGameUniqueId.HF_AZXY5,
    // ]
  },
  {
    filter: 'PK10',
    nameInChinese: 'PK拾',
    showAll: true,
    // games:[
    //     constGameUniqueId.HF_FFPK10,
    //     constGameUniqueId.HF_LFPK10,
    //     constGameUniqueId.HF_WFPK10,
    //     constGameUniqueId.HF_AZXY10,
    //     constGameUniqueId.HF_XYSM,
    //     constGameUniqueId.HF_FFFT,
    //     constGameUniqueId.HF_LFFT,
    //     constGameUniqueId.HF_XYFT,
    // ]
  },
  {
    filter: 'D11',
    nameInChinese: constPlayGroupInChinese.D11,
    showAll: true,
    // games:[
    //     constGameUniqueId.HF_FFD11,
    //     constGameUniqueId.HF_SDD11,
    // ]
  },
  {
    filter: 'K3',
    nameInChinese: constPlayGroupInChinese.K3,
    showAll: true,
    // games:[
    //     constGameUniqueId.HF_FFK3,
    //     constGameUniqueId.HF_LFK3,
    //     constGameUniqueId.HF_WFK3,
    // ]
  },
  {
    filter: 'KL10F',
    nameInChinese: constPlayGroupInChinese.KL10F,
    showAll: true,
    // games:[
    //     constGameUniqueId.HF_AZXY8,
    // ]
  },
  {
    filter: 'SIX',
    nameInChinese: constPlayGroupInChinese.SIX,
    showAll: true,
    // games:[
    //     constGameUniqueId.HF_JSMS,
    //     constGameUniqueId.MC_MARK_SIX,
    //     constGameUniqueId.SG_MARK_SIX,
    //     constGameUniqueId.TW_MARK_SIX,
    //     constGameUniqueId.MARK_SIX,
    // ]
  },
];

export const constTrendGroupList = [
  {
    text: constPlayGroupInChinese.SSC,
    group: constPlayGroup.SSC,
    game: constGameUniqueId.HF_FFSSC,
    searchPlayGroup: constPlayGroup.SSC,
  },
  {
    text: constPlayGroupInChinese.PK10,
    group: constPlayGroup.PK10,
    game: 'HF_AZXY10',
    searchPlayGroup: constPlayGroup.PK10,
  },
  {
    text: constPlayGroupInChinese.D11,
    group: constPlayGroup.D11,
    game: 'HF_FFD11',
    searchPlayGroup: constPlayGroup.D11,
  },
  {
    text: constPlayGroupInChinese.SIX,
    group: constPlayGroup.SIX,
    game: 'HF_FFMS',
    searchPlayGroup: constPlayGroup.SIX,
  },
  {
    text: constPlayGroupInChinese.K3,
    group: constPlayGroup.K3,
    game: 'HF_AZK3',
    searchPlayGroup: constPlayGroup.K3,
  },
  {
    text: constPlayGroupInChinese.KL10F,
    group: constPlayGroup.KL10F,
    game: 'HF_FFKL10F',
    searchPlayGroup: constPlayGroup.KL10F,
  },
  {
    text: constPlayGroupInChinese.PCDD,
    group: constPlayGroup.PCDD,
    game: 'HF_LF28',
    searchPlayGroup: constPlayGroup.PCDD,
  },
  {
    text: constPlayGroupInChinese.OTHER,
    group: constPlayGroup.OTHER,
    game: 'HF_JS3D',
    searchPlayGroup: '3D',
  },
];

export const constTrendsButtonSSC = [
  {
    nameInChinese: constPlayRuleChineseName.jiShiKaiJiang,
    code: constPlayRule.jiShiKaiJiang,
  },
  {
    nameInChinese: constPlayRuleChineseName.luZhuFenXi,
    code: constPlayRule.luZhuFenXi,
  },
  {
    nameInChinese: constPlayRuleChineseName.zongHeFenXi,
    code: constPlayRule.zongHeFenXi,
  },
  {
    nameInChinese: constPlayRuleChineseName.lengReFenXi,
    code: constPlayRule.lengReFenXi,
  },
  {
    nameInChinese: constPlayRuleChineseName.jiBenZouShi,
    code: constPlayRule.jiBenZouShi,
  },
  {
    nameInChinese: constPlayRuleChineseName.dingWeiZouShi,
    code: constPlayRule.dingWeiZouShi,
  },
  {
    nameInChinese: constPlayRuleChineseName.longHuZouShi,
    code: constPlayRule.longHuZouShi,
  },
  {
    nameInChinese: constPlayRuleChineseName.xingTaiZouShi,
    code: constPlayRule.xingTaiZouShi,
  },
  {
    nameInChinese: constPlayRuleChineseName.haoMaLuzhu,
    code: constPlayRule.haoMaLuzhu,
  },
  {
    nameInChinese: constPlayRuleChineseName.daXiaoDanShuangLuzhu,
    code: constPlayRule.daXiaoDanShuangLuzhu,
  },
  {
    nameInChinese: constPlayRuleChineseName.zongHeLuZhu,
    code: constPlayRule.zongHeLuZhu,
  },
  {
    nameInChinese: constPlayRuleChineseName.guanYaHeLuZhu,
    code: constPlayRule.guanYaHeLuZhu,
  },
  {
    nameInChinese: constPlayRuleChineseName.haoMaTongJi,
    code: constPlayRule.haoMaTongJi,
  },
  {
    nameInChinese: constPlayRuleChineseName.liangMianTongJi,
    code: constPlayRule.liangMianTongJi,
  },
  {
    nameInChinese: constPlayRuleChineseName.changLongTongJi,
    code: constPlayRule.changLongTongJi,
  },
  {
    nameInChinese: constPlayRuleChineseName.liShiHaoMa,
    code: constPlayRule.liShiHaoMa,
  },
  {
    nameInChinese: constPlayRuleChineseName.daXiaoDanShuangLiShi,
    code: constPlayRule.daXiaoDanShuangLiShi,
  },
  {
    nameInChinese: constPlayRuleChineseName.pkZhouShi,
    code: constPlayRule.pkZhouShi,
  },
];

export let combineListGame = listOfSSCGames.concat(listOfPK10Games);
combineListGame = combineListGame.concat(listOfXY10Games);
combineListGame = combineListGame.concat(listOfD11Games);
combineListGame = combineListGame.concat(listOfK3Games);
combineListGame = combineListGame.concat(listOfKL10FGames);
combineListGame = combineListGame.concat(listOfPCDDGames);
combineListGame = combineListGame.concat(listOfTHREEDGames);
combineListGame = combineListGame.concat(listOfXPKGames);
combineListGame = combineListGame.concat(listOfQXCGames);
combineListGame = combineListGame.concat(listOfSIXGames);

// const popularGame= combineListGame;
const lowFrequencyGame = [
  constGameUniqueId.MARK_SIX,
  constGameUniqueId.MC_MARK_SIX,
  constGameUniqueId.SG_MARK_SIX,
  constGameUniqueId.TW_MARK_SIX,
  constGameUniqueId.HF_JSMS,
  constGameUniqueId.TT_MARK_SIX,
  constGameUniqueId.NMC_MARK_SIX,
];

export const menu = [
  {
    text: '首页',
    baseUrl: URLS.home,
    url: URLS.home,
  },
  {
    text: '彩票大厅',
    // submenuPopular: popularGame,
    lowFrequencyGame: lowFrequencyGame,
  },
  {
    text: pageNameChinese.tuiJianJiHua,
    baseUrl: URLS.suggestPlan,
    url: `${URLS.suggestPlan}?searchGame=${constGameUniqueId.HF_FFSSC}&group=${constPlayGroup.SSC}&searchPlayGroup=${constPlayGroup.SSC}`,
  },
  {
    text: pageNameChinese.zouShiTuBiao,
    baseUrl: URLS.trendChart,
    url: `${URLS.trendChart}?searchGame=${constGameUniqueId.HF_FFSSC}&group=${constPlayGroup.SSC}&ball=0`,
  },
  {
    text: pageNameChinese.changLongTiXing,
    baseUrl: URLS.changLong,
    url: `${URLS.changLong}?gameUniqueId=${constGameUniqueId.HF_FFSSC}&gameNameInChinese=${constGameNameInChinese.HF_FFSSC}`,
  },
];

export const constPlayRuleByGroup = {
  SSC: [
    constPlayRule.jiShiKaiJiang,
    constPlayRule.changLongTiXing,
    constPlayRule.luZhuFenXi,
    constPlayRule.zongHeFenXi,
    constPlayRule.lengReFenXi,
    constPlayRule.jiBenZouShi,
    constPlayRule.dingWeiZouShi,
    constPlayRule.longHuZouShi,
    constPlayRule.xingTaiZouShi,
    constPlayRule.haoMaLuzhu,
    constPlayRule.daXiaoDanShuangLuzhu,
    constPlayRule.zongHeLuZhu,
    constPlayRule.haoMaTongJi,
    constPlayRule.liangMianTongJi,
    constPlayRule.changLongTongJi,
    constPlayRule.liShiHaoMa,
    constPlayRule.daXiaoDanShuangLiShi,
  ],
  PK10: [
    constPlayRule.jiShiKaiJiang,
    constPlayRule.changLongTiXing,
    constPlayRule.luZhuFenXi,
    constPlayRule.lengReFenXi,
    constPlayRule.jiBenZouShi,
    constPlayRule.dingWeiZouShi,
    constPlayRule.longHuZouShi,
    constPlayRule.haoMaLuzhu,
    constPlayRule.daXiaoDanShuangLuzhu,
    constPlayRule.guanYaHeLuZhu,
    constPlayRule.haoMaTongJi,
    constPlayRule.liangMianTongJi,
    constPlayRule.changLongTongJi,
    constPlayRule.daXiaoDanShuangLiShi,
  ],
  XY10: [
    constPlayRule.jiShiKaiJiang,
    constPlayRule.changLongTiXing,
    constPlayRule.luZhuFenXi,
    constPlayRule.lengReFenXi,
    constPlayRule.jiBenZouShi,
    constPlayRule.dingWeiZouShi,
    constPlayRule.longHuZouShi,
    constPlayRule.haoMaLuzhu,
    constPlayRule.daXiaoDanShuangLuzhu,
    constPlayRule.guanYaHeLuZhu,
    constPlayRule.haoMaTongJi,
    constPlayRule.liangMianTongJi,
    constPlayRule.changLongTongJi,
    constPlayRule.daXiaoDanShuangLiShi,
  ],
  D11: [
    constPlayRule.jiShiKaiJiang,
    constPlayRule.changLongTiXing,
    constPlayRule.luZhuFenXi,
    constPlayRule.lengReFenXi,
    constPlayRule.jiBenZouShi,
    constPlayRule.dingWeiZouShi,
    constPlayRule.longHuZouShi,
    constPlayRule.xingTaiZouShi,
    constPlayRule.haoMaLuzhu,
    constPlayRule.daXiaoDanShuangLuzhu,
    constPlayRule.zongHeLuZhu,
    constPlayRule.haoMaTongJi,
    constPlayRule.liangMianTongJi,
    constPlayRule.changLongTongJi,
    constPlayRule.liShiHaoMa,
    constPlayRule.daXiaoDanShuangLiShi,
  ],
  SIX: [constPlayRule.kaiJiangJilu],
  K3: [
    constPlayRule.jiShiKaiJiang,
    constPlayRule.changLongTiXing,
    constPlayRule.luZhuFenXi,
    constPlayRule.lengReFenXi,
    constPlayRule.jiBenZouShi,
    constPlayRule.dingWeiZouShi,
    constPlayRule.haoMaLuzhu,
    constPlayRule.zongHeLuZhu,
    constPlayRule.haoMaTongJi,
    constPlayRule.changLongTongJi,
    constPlayRule.liShiHaoMa,
  ],
  KL10F: [
    constPlayRule.jiShiKaiJiang,
    constPlayRule.changLongTiXing,
    constPlayRule.luZhuFenXi,
    constPlayRule.lengReFenXi,
    constPlayRule.jiBenZouShi,
    constPlayRule.haoMaLuzhu,
    constPlayRule.daXiaoDanShuangLuzhu,
    constPlayRule.zongHeLuZhu,
    constPlayRule.haoMaTongJi,
    constPlayRule.liangMianTongJi,
    constPlayRule.changLongTongJi,
    constPlayRule.liShiHaoMa,
    constPlayRule.daXiaoDanShuangLiShi,
  ],
  THREED: [constPlayRule.jiShiKaiJiang],
  '3D': [constPlayRule.jiShiKaiJiang],
  XPK: [constPlayRule.pkZhouShi],
  QXC: [constPlayRule.jiShiKaiJiang],
  PCDD: [constPlayRule.jiShiKaiJiang],
  MAX3D: [constPlayRule.jiShiKaiJiang],
};

export const constTrendChartGameByGroup = [
  {
    group: constPlayGroup.SSC,
    groupInChineseName: constPlayGroupInChinese.SSC,
    games: listOfSSCGames,
  },
  {
    group: constPlayGroup.PK10,
    groupInChineseName: constPlayGroupInChinese.PK10,
    games: listOfPK10Games.concat(listOfXY10Games),
  },
  {
    group: constPlayGroup.D11,
    groupInChineseName: constPlayGroupInChinese.D11,
    games: listOfD11Games,
  },
  {
    group: constPlayGroup.K3,
    groupInChineseName: constPlayGroupInChinese.K3,
    games: listOfK3Games,
  },
  {
    group: constPlayGroup.KL10F,
    groupInChineseName: constPlayGroupInChinese.KL10F,
    games: listOfKL10FGames,
  },
  {
    group: constPlayGroup.PCDD,
    groupInChineseName: constPlayGroupInChinese.PCDD,
    games: listOfPCDDGames,
  },
  {
    group: constPlayGroup.OTHER,
    groupInChineseName: constPlayGroupInChinese.OTHER,
    games: listOfTHREEDGames.concat(listOfXPKGames.concat(listOfQXCGames)),
  },
];

export const constChangLongGroup = [
  constPlayGroup.SSC,
  constPlayGroup.PK10,
  constPlayGroup.D11,
  constPlayGroup.K3,
  constPlayGroup.KL10F,
  constPlayGroup.PCDD,
  constPlayGroup.THREED,
  constPlayGroup.XPK,
  constPlayGroup.QXC,
];

// export const constGameByGroup = [
//     {
//         group: constPlayGroup.SSC,
//         groupInChineseName: constPlayGroupInChinese.SSC,
//         games: listOfSSCGames,
//         playRule: constPlayRuleByGroup.SSC
//     },
//     {
//         group: constPlayGroup.PK10,
//         groupInChineseName: constPlayGroupInChinese.PK10,
//         games: listOfPK10Games.concat(listOfXY10Games),
//         playRule: constPlayRuleByGroup.PK10
//     },
//     {
//         group: constPlayGroup.D11,
//         groupInChineseName: constPlayGroupInChinese.D11,
//         games: listOfD11Games,
//         playRule: constPlayRuleByGroup.D11
//     },
//     {
//         group: constPlayGroup.SIX,
//         groupInChineseName: constPlayGroupInChinese.SIX,
//         games: listOfSIXGames,
//         playRule: constPlayRuleByGroup.SIX
//     },
//     {
//         group: constPlayGroup.K3,
//         groupInChineseName: constPlayGroupInChinese.K3,
//         games: listOfK3Games,
//         playRule: constPlayRuleByGroup.K3
//     },
//     {
//         group: constPlayGroup.KL10F,
//         groupInChineseName: constPlayGroupInChinese.KL10F,
//         games: listOfKL10FGames,
//         playRule: constPlayRuleByGroup.KL10F
//     },
//     {
//         group: constPlayGroup.THREED,
//         groupInChineseName: constPlayGroupInChinese.THREED,
//         games: listOfTHREEDGames,
//         playRule: constPlayRuleByGroup.THREED
//     },
//     {
//         group: constPlayGroup.XPK,
//         groupInChineseName: constPlayGroupInChinese.XPK,
//         games: listOfXPKGames,
//         playRule: constPlayRuleByGroup.XPK
//     },
//     {
//         group: constPlayGroup.QXC,
//         groupInChineseName: constPlayGroupInChinese.QXC,
//         games: listOfQXCGames,
//         playRule: constPlayRuleByGroup.QXC
//     },
//     {
//         group: constPlayGroup.PCDD,
//         groupInChineseName: constPlayGroupInChinese.PCDD,
//         games: listOfPCDDGames,
//         playRule: constPlayRuleByGroup.PCDD
//     },
//     {
//         group: constPlayGroup.OTHER,
//         groupInChineseName: constPlayGroupInChinese.OTHER,
//         games: listOfTHREEDGames.concat(listOfXPKGames.concat(listOfQXCGames)),
//         playRule: constPlayRuleByGroup.THREED
//     },
// ]

export const constHomeCardPlayRule = {
  SSC: [
    {
      name: constPlayRuleChineseName.jiShiKaiJiang,
      id: constPlayRule.jiShiKaiJiang,
    },
    {
      name: constPlayRuleChineseName.zongHeFenXi,
      id: constPlayRule.zongHeFenXi,
    },
    {
      name: pageNameChinese.zouShiTuBiao,
      id: pageName.zouShiTuBiao,
    },
    {
      name: pageNameChinese.changLongTiXing,
      id: pageName.changLongTiXing,
    },
  ],
  PK10: [
    {
      name: constPlayRuleChineseName.jiShiKaiJiang,
      id: constPlayRule.jiShiKaiJiang,
    },
    {
      name: constPlayRuleChineseName.luZhuFenXi,
      id: constPlayRule.luZhuFenXi,
    },
    {
      name: pageNameChinese.zouShiTuBiao,
      id: pageName.zouShiTuBiao,
    },
    {
      name: pageNameChinese.changLongTiXing,
      id: pageName.changLongTiXing,
    },
  ],
  XY10: [
    {
      name: constPlayRuleChineseName.jiShiKaiJiang,
      id: constPlayRule.jiShiKaiJiang,
    },
    {
      name: constPlayRuleChineseName.luZhuFenXi,
      id: constPlayRule.luZhuFenXi,
    },
    {
      name: pageNameChinese.zouShiTuBiao,
      id: pageName.zouShiTuBiao,
    },
    {
      name: pageNameChinese.changLongTiXing,
      id: pageName.changLongTiXing,
    },
  ],
  D11: [
    {
      name: constPlayRuleChineseName.jiShiKaiJiang,
      id: constPlayRule.jiShiKaiJiang,
    },
    {
      name: constPlayRuleChineseName.luZhuFenXi,
      id: constPlayRule.luZhuFenXi,
    },
    {
      name: pageNameChinese.zouShiTuBiao,
      id: pageName.zouShiTuBiao,
    },
    {
      name: pageNameChinese.changLongTiXing,
      id: pageName.changLongTiXing,
    },
  ],
  K3: [
    {
      name: constPlayRuleChineseName.jiShiKaiJiang,
      id: constPlayRule.jiShiKaiJiang,
    },
    {
      name: constPlayRuleChineseName.luZhuFenXi,
      id: constPlayRule.luZhuFenXi,
    },
    {
      name: pageNameChinese.zouShiTuBiao,
      id: pageName.zouShiTuBiao,
    },
    {
      name: pageNameChinese.changLongTiXing,
      id: pageName.changLongTiXing,
    },
  ],
  KL10F: [
    {
      name: constPlayRuleChineseName.jiShiKaiJiang,
      id: constPlayRule.jiShiKaiJiang,
    },
    {
      name: constPlayRuleChineseName.luZhuFenXi,
      id: constPlayRule.luZhuFenXi,
    },
    {
      name: pageNameChinese.zouShiTuBiao,
      id: pageName.zouShiTuBiao,
    },
    {
      name: pageNameChinese.changLongTiXing,
      id: pageName.changLongTiXing,
    },
  ],
  SIX: [
    {
      name: constPlayRuleChineseName.kaiJiangJilu,
      id: constPlayRule.kaiJiangJilu,
    },
  ],
};

const generateTrendsPageUrl = (gameUniqueId, group) => {
  return `${URLS.trends}?searchGame=${gameUniqueId}&group=${group}`;
};

export const constHotRecommended = [
  {
    url: generateTrendsPageUrl(constGameUniqueId.HF_FFSSC, constPlayGroup.SSC),
    gameNameInChinese: constGameNameInChinese.HF_FFSSC,
    icon: Images[constGameUniqueId.HF_FFSSC],
  },
  {
    url: generateTrendsPageUrl(constGameUniqueId.HF_LFSSC, constPlayGroup.SSC),
    gameNameInChinese: constGameNameInChinese.HF_LFSSC,
    icon: Images[constGameUniqueId.HF_LFSSC],
  },
  {
    url: generateTrendsPageUrl(constGameUniqueId.HF_WFSSC, constPlayGroup.SSC),
    gameNameInChinese: constGameNameInChinese.HF_WFSSC,
    icon: Images[constGameUniqueId.HF_WFSSC],
  },
  {
    url: generateTrendsPageUrl(
      constGameUniqueId.HF_FFPK10,
      constPlayGroup.PK10,
    ),
    gameNameInChinese: constGameNameInChinese.HF_FFPK10,
    icon: Images[constGameUniqueId.HF_FFPK10],
  },
  {
    url: generateTrendsPageUrl(
      constGameUniqueId.HF_LFPK10,
      constPlayGroup.PK10,
    ),
    gameNameInChinese: constGameNameInChinese.HF_LFPK10,
    icon: Images[constGameUniqueId.HF_LFPK10],
  },
  {
    url: generateTrendsPageUrl(constGameUniqueId.MARK_SIX, constPlayGroup.SIX),
    gameNameInChinese: constGameNameInChinese.MARK_SIX,
    icon: Images[constGameUniqueId.MARK_SIX],
  },
  {
    url: generateTrendsPageUrl(
      constGameUniqueId.SG_MARK_SIX,
      constPlayGroup.SIX,
    ),
    gameNameInChinese: constGameNameInChinese.SG_MARK_SIX,
    icon: Images[constGameUniqueId.SG_MARK_SIX],
  },
  {
    url: generateTrendsPageUrl(
      constGameUniqueId.HF_AZXY8,
      constPlayGroup.KL10F,
    ),
    gameNameInChinese: constGameNameInChinese.HF_AZXY8,
    icon: Images[constGameUniqueId.HF_AZXY8],
  },
];

export const constSideToolsData = [
  {
    icon: Images[constGameUniqueId.HF_FFPK10],
    name: constGameNameInChinese.HF_FFPK10,
    zoushi: [
      {
        name: '基本走势',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFPK10 +
          '&group=' +
          constPlayGroup.PK10 +
          '&rule=jiBenZouShi',
      },
      {
        name: '龙虎走势',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFPK10 +
          '&group=' +
          constPlayGroup.PK10 +
          '&rule=longHuZouShi',
      },
      {
        name: '路珠分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFPK10 +
          '&group=' +
          constPlayGroup.PK10 +
          '&rule=luZhuFenXi',
      },
      {
        name: '冷热分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFPK10 +
          '&group=' +
          constPlayGroup.PK10 +
          '&rule=lengReFenXi',
      },
    ],
  },
  {
    icon: Images[constGameUniqueId.HF_WFPK10],
    name: constGameNameInChinese.HF_WFPK10,
    zoushi: [
      {
        name: '基本走势',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFPK10 +
          '&group=' +
          constPlayGroup.PK10 +
          '&rule=jiBenZouShi',
      },
      {
        name: '龙虎走势',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFPK10 +
          '&group=' +
          constPlayGroup.PK10 +
          '&rule=longHuZouShi',
      },
      {
        name: '路珠分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFPK10 +
          '&group=' +
          constPlayGroup.PK10 +
          '&rule=luZhuFenXi',
      },
      {
        name: '冷热分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFPK10 +
          '&group=' +
          constPlayGroup.PK10 +
          '&rule=lengReFenXi',
      },
    ],
  },
  {
    icon: Images[constGameUniqueId.HF_FFSSC],
    name: constGameNameInChinese.HF_FFSSC,
    zoushi: [
      {
        name: '基本走势',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=jiBenZouShi',
      },
      {
        name: '龙虎走势',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=longHuZouShi',
      },
      {
        name: '路珠分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=luZhuFenXi',
      },
      {
        name: '冷热分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=lengReFenXi',
      },
    ],
    yilou: [
      {
        name: '综合分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_FFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=zongHeFenXi',
      },
    ],
  },
  {
    icon: Images[constGameUniqueId.HF_WFSSC],
    name: constGameNameInChinese.HF_WFSSC,
    zoushi: [
      {
        name: '基本走势',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=jiBenZouShi',
      },
      {
        name: '龙虎走势',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=longHuZouShi',
      },
      {
        name: '路珠分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=luZhuFenXi',
      },
      {
        name: '冷热分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=lengReFenXi',
      },
    ],
    yilou: [
      {
        name: '综合分析',
        url:
          URLS.trends +
          '?searchGame=' +
          constGameUniqueId.HF_WFSSC +
          '&group=' +
          constPlayGroup.SSC +
          '&rule=zongHeFenXi',
      },
    ],
  },
];

export const iframeEnableGameGroup = [constPlayGroup.SIX];
