import { action, decorate, observable, runInAction } from 'mobx';

import {
  currentOrderByBrand,
  getMarkSixByYearConversion,
} from '@services/home';

class RootStore {
  allGames;
  markSixByYearConversion;
  zodiacAnimal;

  constructor() {
    this.fetchResultByBrand();
    this.fetchMarkSixConversion();
  }

  async fetchResultByBrand() {
    let currentOrderByBrandRequest = await currentOrderByBrand();

    if (
      Array.isArray(currentOrderByBrandRequest) &&
      currentOrderByBrandRequest
    ) {
      const groupOrder = [
        'SSC',
        'D11',
        'PK10',
        'XY10',
        'KL10F',
        'K3',
        '3D',
        'XPK',
        'QXC',
        'PCDD',
        'SIX',
      ];
      currentOrderByBrandRequest.sort((a, b) => {
        return (
          groupOrder.indexOf(a.playGroup) - groupOrder.indexOf(b.playGroup) ||
          a.playGroup.localeCompare(b.playGroup) ||
          a.gameUniqueId.localeCompare(b.gameUniqueId)
        );
      });

      runInAction(() => {
        this.allGames = currentOrderByBrandRequest;
      });
    }
  }

  async fetchMarkSixConversion() {
    const currentMarkSixByYearConversion = await getMarkSixByYearConversion();
    if (typeof currentMarkSixByYearConversion === 'object') {
      const { markSixByYear, zodiacAnimal } = currentMarkSixByYearConversion;
      if (markSixByYear !== 'undefined') {
        runInAction(() => {
          this.markSixByYearConversion = markSixByYear;
        });
      }
      if (zodiacAnimal !== 'undefined') {
        runInAction(() => {
          this.zodiacAnimal = zodiacAnimal;
        });
      }
    }
  }

  dehydrate() {
    return {
      allGames: this.allGames,
      markSixByYearConversion: this.markSixByYearConversion,
      zodiacAnimal: this.zodiacAnimal,
    };
  }
}

decorate(RootStore, {
  allGames: observable,
  markSixByYearConversion: observable,
  zodiacAnimal: observable,
  fetchResultByBrand: action,
  fetchMarkSixConversion: action,
});

export default RootStore;
