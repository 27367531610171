import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { objectZodiac, objectElement } from '@const/playRule';

import ribbonRecommendation from '@images/icons/ribbon_recommendation.png';
import bannerSlider1 from '@images/commons/banner/banner-img-1.jpg';
import bannerSlider2 from '@images/commons/banner/banner-img-2.jpg';
import timerIcon from '@images/icons/icn_timer.svg';
import alarm from '@images/icons/icn_alarm.svg';
import whiteTick from '@images/icons/icn_white_tick.svg';
import tick_active from '@images/icons/tick_active.png';
import drop_down_arrow from '@images/icons/icn_arrow_drop_down.svg';
import arrowDownRed from '@images/icons/icn_arrow_down.svg';
import arrowDownBlack from '@images/icons/icn_arrow_down_black.svg';
import arrowDownOrange from '@images/icons/icn_arrow_down_orange.svg';
import arrowDownWhite from '@images/icons/icn_arrow_down_white.svg';
import icn_back from '@images/icons/icn_back.svg';
import tick_mark_red from '@images/icons/icn_tick.svg';
import tick_mark_red_bold from '@images/icons/icn_tick_bold.svg';
import icn_tick_white from '@images/icons/icn_tick_white.svg';
import tick_x_black from '@images/icons/icn_X.svg';
import icn_X_white from '@images/icons/icn_X_white.svg';
import tick_x_black_bold from '@images/icons/icn_X_bold.svg';
import icn_circle_plus from '@images/icons/icn_circle_plus.svg';
import icn_close from '@images/icons/icn_close.svg';
import icn_calendar from '@images/icons/icn_calendar.svg';

// Box number
import pkf1 from '@images/commons/pk10Number/pkf1.png';
import pkf2 from '@images/commons/pk10Number/pkf2.png';
import pkf3 from '@images/commons/pk10Number/pkf3.png';
import pkf4 from '@images/commons/pk10Number/pkf4.png';
import pkf5 from '@images/commons/pk10Number/pkf5.png';
import pkf6 from '@images/commons/pk10Number/pkf6.png';
import pkf7 from '@images/commons/pk10Number/pkf7.png';
import pkf8 from '@images/commons/pk10Number/pkf8.png';
import pkf9 from '@images/commons/pk10Number/pkf9.png';
import pkf10 from '@images/commons/pk10Number/pkf10.png';

// Dice number
import dice1 from '@images/commons/dice/dice1.png';
import dice2 from '@images/commons/dice/dice2.png';
import dice3 from '@images/commons/dice/dice3.png';
import dice4 from '@images/commons/dice/dice4.png';
import dice5 from '@images/commons/dice/dice5.png';
import dice6 from '@images/commons/dice/dice6.png';

// SSC Icons
import HF_BJSSC from '@images/commons/ssc/HF_BJSSC.png';
import HF_FFSSC from '@images/commons/ssc/HF_FFSSC.png';
import HF_LFSSC from '@images/commons/ssc/HF_LFSSC.png';
import HF_CQSSC from '@images/commons/ssc/HF_CQSSC.png';
import HF_TWSSC from '@images/commons/ssc/HF_TWSSC.png';
import HF_WFSSC from '@images/commons/ssc/HF_WFSSC.png';
import HF_TXFFC from '@images/commons/ssc/HF_TXFFC.png';
import PL5 from '@images/commons/ssc/PL5.png';
import HF_HNWFC from '@images/commons/ssc/HF_HNWFC.png';
import HF_HGSSC from '@images/commons/ssc/HF_HGSSC.png';
import HF_XJSSC from '@images/commons/ssc/HF_XJSSC.png';
import HF_TJSSC from '@images/commons/ssc/HF_TJSSC.png';
import HF_AZSSC from '@images/commons/ssc/HF_AZSSC.png';
import HF_HXFFC from '@images/commons/ssc/HF_HXFFC.png';

//PK10 Icon
import HF_FFPK10 from '@images/commons/pk10/HF_FFPK10.png';
import HF_LFPK10 from '@images/commons/pk10/HF_LFPK10.png';
import HF_WFPK10 from '@images/commons/pk10/HF_WFPK10.png';
import HF_BJPK10 from '@images/commons/pk10/HF_BJPK10.png';

//XY10 Icon
import HF_AZXY10 from '@images/commons/xy10/HF_AZXY10.png';

//SM Icon
import HF_XYSM from '@images/commons/sm/HF_XYSM.png';

//XY5 Icon
import HF_AZXY5 from '@images/commons/xy5/HF_AZXY5.png';

//Six Icon
import HF_FFMS from '@images/commons/six/HF_FFMS.png';
import HF_LFMS from '@images/commons/six/HF_LFMS.png';
import MARK_SIX from '@images/commons/six/MARK_SIX.png';
import MC_MARK_SIX from '@images/commons/six/MC_MARK_SIX.png';
import SG_MARK_SIX from '@images/commons/six/SG_MARK_SIX.png';
import TW_MARK_SIX from '@images/commons/six/TW_MARK_SIX.png';
import HF_JSMCMS from '@images/commons/six/HF_JSMCMS.png';
import TW_TT_MARK_SIX from '@images/commons/six/TW_TT_MARK_SIX.png';
import TT_MARK_SIX from '@images/commons/six/TT_MARK_SIX.png';
import NMC_MARK_SIX from '@images/commons/six/NMC_MARK_SIX.png';
import HF_JSMS from '@images/commons/six/HF_JSMS.png';

//FT Icon
import HF_FFFT from '@images/commons/ft/HF_FFFT.png';
import HF_LFFT from '@images/commons/ft/HF_LFFT.png';
import HF_XYFT from '@images/commons/ft/HF_XYFT.png';

//D11 Icon
import HF_AHD11 from '@images/commons/d11/HF_AHD11.png';
import HF_FFD11 from '@images/commons/d11/HF_FFD11.png';
import HF_GDD11 from '@images/commons/d11/HF_GDD11.png';
import HF_JSD11 from '@images/commons/d11/HF_JSD11.png';
import HF_JXD11 from '@images/commons/d11/HF_JXD11.png';
import HF_LFD11 from '@images/commons/d11/HF_LFD11.png';
import HF_SDD11 from '@images/commons/d11/HF_SDD11.png';
import HF_SHD11 from '@images/commons/d11/HF_SHD11.png';
import HF_WFD11 from '@images/commons/d11/HF_WFD11.png';

//K3 Icon
import HF_FFK3 from '@images/commons/k3/HF_FFK3.png';
import HF_LFK3 from '@images/commons/k3/HF_LFK3.png';
import HF_WFK3 from '@images/commons/k3/HF_WFK3.png';
import HF_GSK3 from '@images/commons/k3/HF_GSK3.png';
import HF_HBK3 from '@images/commons/k3/HF_HBK3.png';
import HF_SHK3 from '@images/commons/k3/HF_SHK3.png';
import HF_AHK3 from '@images/commons/k3/HF_AHK3.png';
import HF_QXK3 from '@images/commons/k3/HF_QXK3.png';
import HF_JSK3 from '@images/commons/k3/HF_JSK3.png';
import HF_JLK3 from '@images/commons/k3/HF_JLK3.png';
import HF_BJK3 from '@images/commons/k3/HF_BJK3.png';
import HF_HGK3 from '@images/commons/k3/HF_HGK3.png';
import HF_AZK3 from '@images/commons/k3/HF_AZK3.png';

//KL10F Icon
import HF_AZXY8 from '@images/commons/kl10f/HF_AZXY8.png';
import HF_CQKL10F from '@images/commons/kl10f/HF_CQKL10F.png';
import HF_FFKL10F from '@images/commons/kl10f/HF_FFKL10F.png';
import HF_LFKL10F from '@images/commons/kl10f/HF_LFKL10F.png';
import HF_QDKL10F from '@images/commons/kl10f/HF_QDKL10F.png';
import HF_WFKL10F from '@images/commons/kl10f/HF_WFKL10F.png';

//PCDD Icon
import BJ28 from '@images/commons/pcdd/bj_28.png';
import HF_LF28 from '@images/commons/pcdd/xy_28.png';

//OTHER Icon
import X3D from '@images/commons/other/fc_3d.png';
import PL3 from '@images/commons/other/pl_3.png';
import HF_JS3D from '@images/commons/other/js_3d.png';
import HF_XYPK from '@images/commons/other/xy_pk.png';
import QXC from '@images/commons/other/qxc.png';
import HF_SHSSL from '@images/commons/other/sh_ssl.png';
import FLCKL8 from '@images/commons/other/FLCKL8.png';

//mobile Icon
import icn_dropdown_grey from '@images/icons/icn_dropdown_grey.svg';
import icn_dropdown_up_orange from '@images/icons/icn_dropdown_up_orange.svg';
import icn_dropdown_up_white from '@images/icons/icn_dropdown_up_white.svg';
import icn_dropdown_white from '@images/icons/icn_dropdown_white.svg';
import icn_mobile_menu from '@images/icons/icn_mobile_menu.svg';

//Poker Flower
import clubs from '@images/commons/pokerFlower/clubs.svg';
import diams from '@images/commons/pokerFlower/diams.svg';
import hearts from '@images/commons/pokerFlower/hearts.svg';
import spades from '@images/commons/pokerFlower/spades.svg';

//MAX3D Icon
import HF_QM3D from '@images/commons/max3d/HF_QM3D.png';
import HF_WFM3D from '@images/commons/max3d/HF_WFM3D.png';
import VNM3D from '@images/commons/max3d/VNM3D.png';

//Zodiac
import cat from '@images/commons/zodiac/cat.png';
import cattle from '@images/commons/zodiac/cattle.png';
import chick from '@images/commons/zodiac/chick.png';
import dog from '@images/commons/zodiac/dog.png';
import dragon from '@images/commons/zodiac/dragon.png';
import horse from '@images/commons/zodiac/horse.png';
import monkey from '@images/commons/zodiac/monkey.png';
import mouse from '@images/commons/zodiac/mouse.png';
import pig from '@images/commons/zodiac/pig.png';
import rabbit from '@images/commons/zodiac/rabbit.png';
import sheep from '@images/commons/zodiac/sheep.png';
import snake from '@images/commons/zodiac/snake.png';
import tiger from '@images/commons/zodiac/tiger.png';

//Zodiac with Border
import cat_wth_border from '@images/commons/zodiac/wthBorder/cat.png';
import cattle_wth_border from '@images/commons/zodiac/wthBorder/cattle.png';
import chick_wth_border from '@images/commons/zodiac/wthBorder/chick.png';
import dog_wth_border from '@images/commons/zodiac/wthBorder/dog.png';
import dragon_wth_border from '@images/commons/zodiac/wthBorder/dragon.png';
import horse_wth_border from '@images/commons/zodiac/wthBorder/horse.png';
import monkey_wth_border from '@images/commons/zodiac/wthBorder/monkey.png';
import mouse_wth_border from '@images/commons/zodiac/wthBorder/mouse.png';
import pig_wth_border from '@images/commons/zodiac/wthBorder/pig.png';
import rabbit_wth_border from '@images/commons/zodiac/wthBorder/rabbit.png';
import sheep_wth_border from '@images/commons/zodiac/wthBorder/sheep.png';
import snake_wth_border from '@images/commons/zodiac/wthBorder/snake.png';
import tiger_wth_border from '@images/commons/zodiac/wthBorder/tiger.png';

//Zodiac
import fire from '@images/commons/element/fire.png';
import gold from '@images/commons/element/gold.png';
import mountain from '@images/commons/element/mountain.png';
import water from '@images/commons/element/water.png';
import wood from '@images/commons/element/wood.png';

//Zodiac with Border
import fire_wth_border from '@images/commons/element/wthBorder/fire.png';
import gold_wth_border from '@images/commons/element/wthBorder/gold.png';
import mountain_wth_border from '@images/commons/element/wthBorder/mountain.png';
import water_wth_border from '@images/commons/element/wthBorder/water.png';
import wood_wth_border from '@images/commons/element/wthBorder/wood.png';

// red dragon, blue tiger
import icn_blue_tiger from '@images/icons/icn_blue_tiger.png';
import icn_red_dragon from '@images/icons/icn_red_dragon.png';
import icn_white_tiger from '@images/icons/icn_white_tiger.png';
import icn_white_dragon from '@images/icons/icn_white_dragon.png';

export const Images = {
  ribbonRecommendation,
  bannerSlider1,
  bannerSlider2,
  timerIcon,
  alarm,
  whiteTick,
  tick_active,
  drop_down_arrow,
  arrowDownRed,
  arrowDownBlack,
  arrowDownWhite,
  arrowDownOrange,
  icn_back,
  pkf1,
  pkf2,
  pkf3,
  pkf4,
  pkf5,
  pkf6,
  pkf7,
  pkf8,
  pkf9,
  pkf10,
  dice1,
  dice2,
  dice3,
  dice4,
  dice5,
  dice6,
  HF_TXFFC,
  HF_BJSSC,
  HF_FFSSC,
  HF_LFSSC,
  HF_CQSSC,
  HF_TWSSC,
  HF_WFSSC,
  HF_HNWFC,
  HF_XJSSC,
  HF_HGSSC,
  HF_TJSSC,
  HF_AZSSC,
  HF_HXFFC,
  PL5,
  HF_FFPK10,
  HF_LFPK10,
  HF_WFPK10,
  HF_BJPK10,
  HF_AZXY10,
  HF_XYSM,
  HF_AZXY5,
  HF_FFMS,
  HF_LFMS,
  MARK_SIX,
  MC_MARK_SIX,
  SG_MARK_SIX,
  TW_MARK_SIX,
  HF_JSMCMS,
  TW_TT_MARK_SIX,
  TT_MARK_SIX,
  NMC_MARK_SIX,
  HF_JSMS,
  HF_FFFT,
  HF_LFFT,
  HF_XYFT,
  HF_AHD11,
  HF_FFD11,
  HF_GDD11,
  HF_JSD11,
  HF_JXD11,
  HF_LFD11,
  HF_SDD11,
  HF_SHD11,
  HF_WFD11,
  HF_FFK3,
  HF_LFK3,
  HF_WFK3,
  HF_GSK3,
  HF_HBK3,
  HF_SHK3,
  HF_AHK3,
  HF_QXK3,
  HF_JSK3,
  HF_JLK3,
  HF_BJK3,
  HF_HGK3,
  HF_AZK3,
  HF_AZXY8,
  HF_CQKL10F,
  HF_FFKL10F,
  HF_LFKL10F,
  HF_QDKL10F,
  HF_WFKL10F,
  tick_mark_red,
  tick_mark_red_bold,
  tick_x_black,
  tick_x_black_bold,
  icn_circle_plus,
  icn_close,
  BJ28,
  HF_LF28,
  X3D,
  PL3,
  HF_JS3D,
  HF_XYPK,
  QXC,
  HF_SHSSL,
  FLCKL8,
  icn_dropdown_grey,
  icn_dropdown_up_orange,
  icn_dropdown_up_white,
  icn_dropdown_white,
  icn_mobile_menu,
  icn_calendar,
  clubs,
  diams,
  hearts,
  spades,
  HF_QM3D,
  HF_WFM3D,
  VNM3D,
  cat,
  cattle,
  chick,
  dog,
  dragon,
  horse,
  monkey,
  mouse,
  pig,
  rabbit,
  sheep,
  snake,
  tiger,
  cat_wth_border,
  cattle_wth_border,
  chick_wth_border,
  dog_wth_border,
  dragon_wth_border,
  horse_wth_border,
  monkey_wth_border,
  mouse_wth_border,
  pig_wth_border,
  rabbit_wth_border,
  sheep_wth_border,
  snake_wth_border,
  tiger_wth_border,
  fire,
  gold,
  mountain,
  water,
  wood,
  fire_wth_border,
  gold_wth_border,
  mountain_wth_border,
  water_wth_border,
  wood_wth_border,
  icn_blue_tiger,
  icn_red_dragon,
  icn_tick_white,
  icn_X_white,
  icn_white_tiger,
  icn_white_dragon,
};
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    )}
  />
);
export default Image;

const { 鼠, 牛, 虎, 兔, 龙, 蛇, 马, 羊, 猴, 鸡, 狗, 猪, 猫 } = objectZodiac;

const { 金, 木, 水, 火, 土 } = objectElement;

export const MarkSixImage = ({ name, border = false }) => {
  let icon;
  if (border) {
    switch (name) {
      case 鼠:
        icon = mouse_wth_border;
        break;
      case 牛:
        icon = cattle_wth_border;
        break;
      case 虎:
        icon = tiger_wth_border;
        break;
      case 兔:
        icon = rabbit_wth_border;
        break;
      case 龙:
        icon = dragon_wth_border;
        break;
      case 蛇:
        icon = snake_wth_border;
        break;
      case 马:
        icon = horse_wth_border;
        break;
      case 羊:
        icon = sheep_wth_border;
        break;
      case 猴:
        icon = monkey_wth_border;
        break;
      case 鸡:
        icon = chick_wth_border;
        break;
      case 狗:
        icon = dog_wth_border;
        break;
      case 猪:
        icon = pig_wth_border;
        break;
      case 猫:
        icon = cat_wth_border;
        break;
      case 金:
        icon = gold_wth_border;
        break;
      case 木:
        icon = wood_wth_border;
        break;
      case 水:
        icon = water_wth_border;
        break;
      case 火:
        icon = fire_wth_border;
        break;
      case 土:
        icon = mountain_wth_border;
        break;
      default:
        break;
    }
  } else {
    switch (name) {
      case 鼠:
        icon = mouse;
        break;
      case 牛:
        icon = cattle;
        break;
      case 虎:
        icon = tiger;
        break;
      case 兔:
        icon = rabbit;
        break;
      case 龙:
        icon = dragon;
        break;
      case 蛇:
        icon = snake;
        break;
      case 马:
        icon = horse;
        break;
      case 羊:
        icon = sheep;
        break;
      case 猴:
        icon = monkey;
        break;
      case 鸡:
        icon = chick;
        break;
      case 狗:
        icon = dog;
        break;
      case 猪:
        icon = pig;
        break;
      case 猫:
        icon = cat;
        break;
      case 金:
        icon = gold;
        break;
      case 木:
        icon = wood;
        break;
      case 水:
        icon = water;
        break;
      case 火:
        icon = fire;
        break;
      case 土:
        icon = mountain;
        break;
      default:
        break;
    }
  }

  return <img src={icon} alt={name} />;
};
