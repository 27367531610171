// immutable
const orange = '#FF7043';
const orangeTransparent = '#FF916E';
const lightOrange = '#FFF4F0';
const yellow = '#FFF400';
const lightYellow = '#FFB143';
const opacityYellow = '#FFF5EC';
const backgroundLightYellow = '#FFFCF6';
const white = '#fff';
const softWhite = '#EBEBEB';
const charcoalGrey = '#2E2F30';
const lightGrey = '#AAAAAA';
const fontGrey = '#666666';
const lightFontGrey = '#999999';
const borderGrey = '#E5E5E5';
const backgroundGrey = '#FAFAFA';
const bodyGrey = '#EEEEEE';
const greyLine = '#D9D9D9';
const darkGrey = '#ABABAB';
const black = '#000000';
const mobileBlack = '#333333';
const softBlue = '#CEE6F8';
const mint = '#CEF8E6';
const separator = '#575758';
const red = '#BF2121';
const lightRed = '#F97979';
const opacityRed = '#FFEFEF';
const blue = '#2145D7';
const lightBlue = '#6290F5';
const opacityBlue = '#EDF3FE';
const green = '#0DAE1C';
const lightGreen = '#71C48A';
const opacityGreen = '#EFFBF3';
const tableBorderGrey = '#D6D6D6';
const lightPurple = '#8376F7';
const opacityPurple = '#F4F2FF';

export const color = {
  lightGrey,
  softWhite,
  bodyGrey,
  white,
  black,
  mobileBlack,
  fontGrey,
  lightFontGrey,
  darkGrey,
  orange,
  orangeTransparent,
  lightOrange,
  charcoalGrey,
  lightYellow,
  opacityYellow,
  backgroundLightYellow,
  borderGrey,
  backgroundGrey,
  softBlue,
  mint,
  greyLine,
  separator,
  yellow,
  red,
  lightRed,
  opacityRed,
  blue,
  lightBlue,
  opacityBlue,
  green,
  lightGreen,
  opacityGreen,
  tableBorderGrey,
  lightPurple,
  opacityPurple,
};
